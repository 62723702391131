// src/pages/RegisterPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUserAndRestaurant } from '../services/api'; // Updated import
import 'bootstrap/dist/css/bootstrap.min.css';
import './RegisterPage.css'; // Custom styling for the registration page
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import countryList from 'country-list';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap

// Define a list of all ISO 4217 currencies
const currencyOptions = [
    { value: 'USD', label: 'USD - US Dollar' },
    { value: 'EUR', label: 'EUR - Euro' },
    { value: 'GBP', label: 'GBP - British Pound' },
    { value: 'JPY', label: 'JPY - Japanese Yen' },
    { value: 'AUD', label: 'AUD - Australian Dollar' },
    { value: 'CAD', label: 'CAD - Canadian Dollar' },
    { value: 'CHF', label: 'CHF - Swiss Franc' },
    { value: 'CNY', label: 'CNY - Chinese Yuan' },
    { value: 'SEK', label: 'SEK - Swedish Krona' },
    { value: 'NZD', label: 'NZD - New Zealand Dollar' },
    { value: 'MXN', label: 'MXN - Mexican Peso' },
    { value: 'SGD', label: 'SGD - Singapore Dollar' },
    { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
    { value: 'NOK', label: 'NOK - Norwegian Krone' },
    { value: 'KRW', label: 'KRW - South Korean Won' },
    { value: 'TRY', label: 'TRY - Turkish Lira' },
    { value: 'INR', label: 'INR - Indian Rupee' },
    { value: 'RUB', label: 'RUB - Russian Ruble' },
    { value: 'BRL', label: 'BRL - Brazilian Real' },
    { value: 'ZAR', label: 'ZAR - South African Rand' },
    { value: 'SAR', label: 'SAR - Saudi Riyal' },
    { value: 'AED', label: 'AED - Emirati Dirham' },
    { value: 'ARS', label: 'ARS - Argentine Peso' },
    { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
    { value: 'THB', label: 'THB - Thai Baht' },
    { value: 'PLN', label: 'PLN - Polish Zloty' },
    { value: 'DKK', label: 'DKK - Danish Krone' },
    { value: 'HUF', label: 'HUF - Hungarian Forint' },
    { value: 'CZK', label: 'CZK - Czech Koruna' },
    { value: 'ILS', label: 'ILS - Israeli Shekel' },
    { value: 'CLP', label: 'CLP - Chilean Peso' },
    { value: 'PHP', label: 'PHP - Philippine Peso' },
    { value: 'PKR', label: 'PKR - Pakistani Rupee' },
    { value: 'VND', label: 'VND - Vietnamese Dong' },
    { value: 'BDT', label: 'BDT - Bangladeshi Taka' },
    { value: 'EGP', label: 'EGP - Egyptian Pound' },
    { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
    { value: 'NGN', label: 'NGN - Nigerian Naira' },
    { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
    { value: 'COP', label: 'COP - Colombian Peso' },
    { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
    { value: 'KZT', label: 'KZT - Kazakhstani Tenge' },
    { value: 'KWD', label: 'KWD - Kuwaiti Dinar' },
    { value: 'BHD', label: 'BHD - Bahraini Dinar' },
    { value: 'OMR', label: 'OMR - Omani Rial' },
    { value: 'QAR', label: 'QAR - Qatari Riyal' },
    { value: 'JOD', label: 'JOD - Jordanian Dinar' },
    { value: 'MAD', label: 'MAD - Moroccan Dirham' },
    { value: 'DZD', label: 'DZD - Algerian Dinar' },
    { value: 'TND', label: 'TND - Tunisian Dinar' },
    { value: 'LKR', label: 'LKR - Sri Lankan Rupee' },
    { value: 'XAF', label: 'XAF - Central African CFA Franc' },
    { value: 'XOF', label: 'XOF - West African CFA Franc' },
    { value: 'GHS', label: 'GHS - Ghanaian Cedi' },
    { value: 'KES', label: 'KES - Kenyan Shilling' },
    { value: 'TZS', label: 'TZS - Tanzanian Shilling' },
    { value: 'UGX', label: 'UGX - Ugandan Shilling' },
    { value: 'ZMW', label: 'ZMW - Zambian Kwacha' },
    { value: 'MWK', label: 'MWK - Malawian Kwacha' },
    { value: 'BWP', label: 'BWP - Botswana Pula' },
    { value: 'MZN', label: 'MZN - Mozambican Metical' },
    { value: 'GEL', label: 'GEL - Georgian Lari' },
    { value: 'AZN', label: 'AZN - Azerbaijani Manat' },
    { value: 'BYN', label: 'BYN - Belarusian Ruble' },
    { value: 'AMD', label: 'AMD - Armenian Dram' },
    { value: 'UZS', label: 'UZS - Uzbekistani Som' },
    { value: 'MNT', label: 'MNT - Mongolian Tugrik' },
    { value: 'KGS', label: 'KGS - Kyrgyzstani Som' },
    { value: 'AFN', label: 'AFN - Afghan Afghani' },
    { value: 'NPR', label: 'NPR - Nepalese Rupee' },
    { value: 'BND', label: 'BND - Brunei Dollar' },
    { value: 'KHR', label: 'KHR - Cambodian Riel' },
    { value: 'LAK', label: 'LAK - Lao Kip' },
    { value: 'MMK', label: 'MMK - Myanmar Kyat' },
    { value: 'MOP', label: 'MOP - Macanese Pataca' },
    { value: 'IRR', label: 'IRR - Iranian Rial' },
    { value: 'IQD', label: 'IQD - Iraqi Dinar' },
    { value: 'LBP', label: 'LBP - Lebanese Pound' },
    { value: 'SYP', label: 'SYP - Syrian Pound' },
    { value: 'YER', label: 'YER - Yemeni Rial' },
    { value: 'XCD', label: 'XCD - East Caribbean Dollar' },
    { value: 'TTD', label: 'TTD - Trinidad and Tobago Dollar' },
    { value: 'JMD', label: 'JMD - Jamaican Dollar' },
    { value: 'BBD', label: 'BBD - Barbadian Dollar' },
    { value: 'BZD', label: 'BZD - Belize Dollar' },
    { value: 'BSD', label: 'BSD - Bahamian Dollar' },
    { value: 'BMD', label: 'BMD - Bermudian Dollar' },
    { value: 'HTG', label: 'HTG - Haitian Gourde' },
    { value: 'DOP', label: 'DOP - Dominican Peso' },
    { value: 'FJD', label: 'FJD - Fijian Dollar' },
    { value: 'PGK', label: 'PGK - Papua New Guinean Kina' },
    { value: 'SBD', label: 'SBD - Solomon Islands Dollar' },
    { value: 'VUV', label: 'VUV - Vanuatu Vatu' },
    { value: 'TOP', label: 'TOP - Tongan Paʻanga' },
    { value: 'WST', label: 'WST - Samoan Tala' },
    { value: 'KPW', label: 'KPW - North Korean Won' },
    { value: 'SLL', label: 'SLL - Sierra Leonean Leone' },
    { value: 'GNF', label: 'GNF - Guinean Franc' },
    { value: 'MGA', label: 'MGA - Malagasy Ariary' },
    { value: 'SCR', label: 'SCR - Seychellois Rupee' },
    { value: 'MVR', label: 'MVR - Maldivian Rufiyaa' },
    { value: 'BAM', label: 'BAM - Bosnian Convertible Mark' },
    { value: 'ALL', label: 'ALL - Albanian Lek' },
    { value: 'MKD', label: 'MKD - Macedonian Denar' },
    { value: 'MDL', label: 'MDL - Moldovan Leu' },
    { value: 'RON', label: 'RON - Romanian Leu' },
    { value: 'HRK', label: 'HRK - Croatian Kuna' },
    { value: 'BGN', label: 'BGN - Bulgarian Lev' },
    { value: 'RSD', label: 'RSD - Serbian Dinar' },
    { value: 'ISK', label: 'ISK - Icelandic Krona' },
    { value: 'LTL', label: 'LTL - Lithuanian Litas' },
    { value: 'LVL', label: 'LVL - Latvian Lats' },
    { value: 'EEK', label: 'EEK - Estonian Kroon' }
];

const RegisterPage = () => {
    const { t, i18n } = useTranslation(); // Initialize translation
    const [step, setStep] = useState(1); // Track the current step
    const [userData, setUserData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
    });
    const [restaurantBasicData, setRestaurantBasicData] = useState({
        name: '',
        address: '',
        phone_number: '',
        // Removed 'tables' field
    });
    const [restaurantAdditionalData, setRestaurantAdditionalData] = useState({
        country: null,
        city: '',
        postal_code: '',
        currency: null,
        number_of_employees: 1,
    });
    const [errors, setErrors] = useState({}); // Updated error state
    const [success, setSuccess] = useState(''); // Success message
    const [showSuccessModal, setShowSuccessModal] = useState(false); // State for Success Modal
    const navigate = useNavigate();

    // Options for Select components
    const countryOptions = countryList.getData().map(country => ({
        value: country.code,
        label: country.name,
    }));

    // Handle form input changes for user data
    const handleUserChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({ ...prev, [name]: value }));
    };

    // Handle form input changes for restaurant basic data
    const handleBasicChange = (e) => {
        const { name, value } = e.target;
        setRestaurantBasicData(prev => ({ ...prev, [name]: value }));
    };

    // Handle form input changes for restaurant additional data
    const handleAdditionalChange = (e) => {
        const { name, value } = e.target;
        setRestaurantAdditionalData(prev => ({ ...prev, [name]: value }));
    };

    // Handle Select changes for restaurant additional data
    const handleSelectChange = (selectedOption, { name }) => {
        setRestaurantAdditionalData(prev => ({ ...prev, [name]: selectedOption }));
    };

    // Handle phone number change separately
    const handlePhoneChange = (value) => {
        setRestaurantBasicData(prev => ({ ...prev, phone_number: value }));
    };

    // Validation for Step 1: User Information
    const validateStep1 = () => {
        const newErrors = {};

        // Username: required, min length 3, alphanumeric
        if (!userData.username.trim()) {
            newErrors.username = t('register.errors.usernameRequired');
        } else if (userData.username.length < 3) {
            newErrors.username = t('register.errors.usernameTooShort');
        } else if (!/^[a-zA-Z0-9_]+$/.test(userData.username)) {
            newErrors.username = t('register.errors.usernameInvalid');
        }

        // Email: required, valid format
        if (!userData.email.trim()) {
            newErrors.email = t('register.errors.emailRequired');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
            newErrors.email = t('register.errors.emailInvalid');
        }

        // Password: required, min length 6, must include letters and numbers
        if (!userData.password) {
            newErrors.password = t('register.errors.passwordRequired');
        } else if (userData.password.length < 6) {
            newErrors.password = t('register.errors.passwordTooShort');
        } else if (!/(?=.*[A-Za-z])(?=.*\d)/.test(userData.password)) {
            newErrors.password = t('register.errors.passwordWeak');
        }

        // Confirm Password: required, must match password
        if (!userData.confirmPassword) {
            newErrors.confirmPassword = t('register.errors.confirmPasswordRequired');
        } else if (userData.confirmPassword !== userData.password) {
            newErrors.confirmPassword = t('register.errors.passwordsDoNotMatch');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validation for Step 2: Restaurant Basic Information
    const validateStep2 = () => {
        const newErrors = {};

        // Name: required
        if (!restaurantBasicData.name.trim()) {
            newErrors.name = t('register.errors.restaurantNameRequired');
        }

        // Address: required
        if (!restaurantBasicData.address.trim()) {
            newErrors.address = t('register.errors.addressRequired');
        }

        // Phone Number: required, valid format
        if (!restaurantBasicData.phone_number.trim()) {
            newErrors.phone_number = t('register.errors.phoneNumberRequired');
        } else if (restaurantBasicData.phone_number.length < 10) {
            newErrors.phone_number = t('register.errors.phoneNumberInvalid');
        }

        // Removed validation for 'tables'

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validation for Step 3: Restaurant Additional Information
    const validateStep3 = () => {
        const newErrors = {};

        // Country: required
        if (!restaurantAdditionalData.country) {
            newErrors.country = t('register.errors.countryRequired');
        }

        // City: required
        if (!restaurantAdditionalData.city.trim()) {
            newErrors.city = t('register.errors.cityRequired');
        }

        // Currency: required
        if (!restaurantAdditionalData.currency) {
            newErrors.currency = t('register.errors.currencyRequired');
        }

        // Number of Employees: required, positive integer
        if (!restaurantAdditionalData.number_of_employees) {
            newErrors.number_of_employees = t('register.errors.numberOfEmployeesRequired');
        } else if (restaurantAdditionalData.number_of_employees < 1) {
            newErrors.number_of_employees = t('register.errors.numberOfEmployeesInvalid');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle navigation between steps with validation
    const handleNext = () => {
        let isValid = false;

        if (step === 1) {
            isValid = validateStep1();
        } else if (step === 2) {
            isValid = validateStep2();
        }

        if (isValid) {
            setErrors({});
            setStep(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        setErrors({});
        setStep(prev => prev - 1);
    };

    // Handle submission for combined user and restaurant registration
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        // Only submit on the last step
        if (step === 3) {
            const isValid = validateStep3();

            if (!isValid) {
                return;
            }

            // Additional validation: passwords
            if (userData.password !== userData.confirmPassword) {
                setErrors(prev => ({ ...prev, confirmPassword: t('register.errors.passwordsDoNotMatch') }));
                return;
            }

            // Prepare payload
            const registrationPayload = {
                user: {
                    username: userData.username,
                    password: userData.password,
                    email: userData.email,
                },
                restaurant: {
                    name: restaurantBasicData.name,
                    address: restaurantBasicData.address,
                    phone_number: restaurantBasicData.phone_number,
                    // Removed 'tables' field
                    country: restaurantAdditionalData.country.value, // Send country code
                    city: restaurantAdditionalData.city, // Send city as text input
                    postal_code: restaurantAdditionalData.postal_code || '0000', // Default to '0000' if empty
                    currency: restaurantAdditionalData.currency.value, // Send currency code
                    number_of_employees: restaurantAdditionalData.number_of_employees,
                },
            };

            try {
                // Send combined registration data
                const response = await registerUserAndRestaurant(registrationPayload);
                setSuccess(t('register.success.registrationComplete'));
                setShowSuccessModal(true); // Show success modal
            } catch (err) {
                // Handle errors from the API
                if (err.response && err.response.data) {
                    const apiError = err.response.data;
                    console.log('API Error:', apiError); // For debugging
                    if (apiError.error) {
                        setErrors(prev => ({ ...prev, api: t(`register.errors.${apiError.error}`) || t('register.errors.unexpectedError') }));
                    } else if (apiError.user) {
                        if (apiError.user.username) {
                            setErrors(prev => ({ ...prev, username: t('register.errors.usernameExists') }));
                        } else if (apiError.user.email) {
                            setErrors(prev => ({ ...prev, email: t('register.errors.emailExists') }));
                        }
                    } else if (apiError.restaurant) {
                        setErrors(prev => ({ ...prev, api: t(`register.errors.${apiError.restaurant}`) || t('register.errors.unexpectedError') }));
                    } else {
                        setErrors(prev => ({ ...prev, api: t('register.errors.unexpectedError') }));
                        console.error('Unexpected error format:', apiError);
                    }
                } else {
                    setErrors(prev => ({ ...prev, api: t('register.errors.unexpectedError') }));
                }
            }
        }
    };

    // Handle closing the success modal and redirecting to login
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate('/login');
    };

    return (
        <div
            className="register-page-container d-flex align-items-center justify-content-center"
            style={{
                minHeight: '100vh',
                background: 'linear-gradient(135deg, #007bff, #00c853)', // Match How It Works background
            }}
        >
            <div
                className="register-box p-5 shadow"
                style={{ backgroundColor: '#fff', borderRadius: '10px', width: '100%' }} // Changed width to 100% for better responsiveness
            >
                <h2 className="text-center mb-4">{t('register.title')}</h2>
                {/* Remove the general error message as errors are now field-specific */}
                {/* {error && <p className="text-danger text-center">{error}</p>} */}

                <form onSubmit={handleSubmit} className="text-right" dir={i18n.dir()}>
                    {/* Step Indicators */}
                    <div className="d-flex justify-content-center mb-4">
                        <div className={`step-indicator ${step === 1 ? 'active' : ''}`}>
                            <span className="step-number">1</span>
                        </div>
                        <div className={`step-indicator ${step === 2 ? 'active' : ''}`}>
                            <span className="step-number">2</span>
                        </div>
                        <div className={`step-indicator ${step === 3 ? 'active' : ''}`}>
                            <span className="step-number">3</span>
                        </div>
                    </div>

                    {/* Step 1: User Information */}
                    {step === 1 && (
                        <>
                            <div className="form-group mb-3">
                                <label htmlFor="username">{t('register.userForm.username')}</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                    placeholder={t('register.userForm.placeholders.username')}
                                    value={userData.username}
                                    onChange={handleUserChange}
                                    required
                                />
                                {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="email">{t('register.userForm.email')}</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder={t('register.userForm.placeholders.email')}
                                    value={userData.email}
                                    onChange={handleUserChange}
                                    required
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">{t('register.userForm.password')}</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder={t('register.userForm.placeholders.password')}
                                    value={userData.password}
                                    onChange={handleUserChange}
                                    required
                                />
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="confirmPassword">{t('register.userForm.confirmPassword')}</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                    placeholder={t('register.userForm.placeholders.confirmPassword')}
                                    value={userData.confirmPassword}
                                    onChange={handleUserChange}
                                    required
                                />
                                {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                            </div>

                            {errors.api && <p className="text-danger text-center">{errors.api}</p>}

                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" className="btn btn-primary" onClick={handleNext}>
                                    {t('register.buttons.next')}
                                </button>
                            </div>
                        </>
                    )}

                    {/* Step 2: Restaurant Basic Information */}
                    {step === 2 && (
                        <>
                            <div className="form-group mb-3">
                                <label htmlFor="name">{t('register.restaurantForm.name')}</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.name')}
                                    value={restaurantBasicData.name}
                                    onChange={handleBasicChange}
                                    required
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="address">{t('register.restaurantForm.address')}</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.address')}
                                    value={restaurantBasicData.address}
                                    onChange={handleBasicChange}
                                    required
                                />
                                {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="phone_number">{t('register.restaurantForm.phoneNumber')}</label>
                                <PhoneInput
                                    country={'nl'}
                                    value={restaurantBasicData.phone_number}
                                    onChange={handlePhoneChange}
                                    inputProps={{
                                        name: 'phone_number',
                                        required: true,
                                        className: `form-control ${errors.phone_number ? 'is-invalid' : ''}`,
                                        placeholder: t('register.restaurantForm.placeholders.phoneNumber'),
                                    }}
                                    containerStyle={{ width: '100%' }}
                                    inputStyle={{ width: '100%' }}
                                    buttonStyle={{ borderLeft: 'none' }} // Aligns the country code on the left
                                    specialLabel=""
                                />
                                {errors.phone_number && <div className="invalid-feedback d-block">{errors.phone_number}</div>}
                            </div>
                            {/* Removed the 'tables' input field */}
                            {/* <div className="form-group mb-3">
                                <label htmlFor="tables">{t('register.restaurantForm.tables')}</label>
                                <input
                                    type="number"
                                    id="tables"
                                    name="tables"
                                    className={`form-control ${errors.tables ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.tables')}
                                    value={restaurantBasicData.tables}
                                    onChange={handleBasicChange}
                                    min="1"
                                    required
                                />
                                {errors.tables && <div className="invalid-feedback">{errors.tables}</div>}
                            </div> */}

                            {errors.api && <p className="text-danger text-center">{errors.api}</p>}

                            <div className="d-flex justify-content-between mt-4">
                                <button type="button" className="btn btn-secondary" onClick={handlePrevious}>
                                    {t('register.buttons.previous')}
                                </button>
                                <button type="button" className="btn btn-primary" onClick={handleNext}>
                                    {t('register.buttons.next')}
                                </button>
                            </div>
                        </>
                    )}

                    {/* Step 3: Restaurant Additional Information */}
                    {step === 3 && (
                        <>
                            <div className="form-group mb-3">
                                <label htmlFor="country">{t('register.restaurantForm.country')}</label>
                                <Select
                                    id="country"
                                    name="country"
                                    options={countryOptions}
                                    value={restaurantAdditionalData.country}
                                    onChange={handleSelectChange}
                                    placeholder={t('register.restaurantForm.placeholders.country')}
                                    isClearable
                                    className={errors.country ? 'is-invalid' : ''}
                                />
                                {errors.country && <div className="invalid-feedback d-block">{errors.country}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="city">{t('register.restaurantForm.city')}</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.city')}
                                    value={restaurantAdditionalData.city}
                                    onChange={handleAdditionalChange}
                                    required
                                />
                                {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="postal_code">{t('register.restaurantForm.postalCode')}</label>
                                <input
                                    type="text"
                                    id="postal_code"
                                    name="postal_code"
                                    className={`form-control ${errors.postal_code ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.postalCode')}
                                    value={restaurantAdditionalData.postal_code}
                                    onChange={handleAdditionalChange}
                                />
                                {errors.postal_code && <div className="invalid-feedback">{errors.postal_code}</div>}
                                <small className="form-text text-muted">
                                    {t('register.restaurantForm.postalCodeNote')}
                                </small>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="currency">{t('register.restaurantForm.currency')}</label>
                                <Select
                                    id="currency"
                                    name="currency"
                                    options={currencyOptions}
                                    value={restaurantAdditionalData.currency}
                                    onChange={handleSelectChange}
                                    placeholder={t('register.restaurantForm.placeholders.currency')}
                                    isClearable
                                    className={errors.currency ? 'is-invalid' : ''}
                                />
                                {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="number_of_employees">{t('register.restaurantForm.numberOfEmployees')}</label>
                                <input
                                    type="number"
                                    id="number_of_employees"
                                    name="number_of_employees"
                                    className={`form-control ${errors.number_of_employees ? 'is-invalid' : ''}`}
                                    placeholder={t('register.restaurantForm.placeholders.numberOfEmployees')}
                                    value={restaurantAdditionalData.number_of_employees}
                                    onChange={handleAdditionalChange}
                                    min="1"
                                    required
                                />
                                {errors.number_of_employees && <div className="invalid-feedback">{errors.number_of_employees}</div>}
                            </div>

                            {errors.api && <p className="text-danger text-center">{errors.api}</p>}

                            <div className="d-flex justify-content-between mt-4">
                                <button type="button" className="btn btn-secondary" onClick={handlePrevious}>
                                    {t('register.buttons.previous')}
                                </button>
                                <button type="submit" className="btn btn-success">
                                    {t('register.buttons.finish')}
                                </button>
                            </div>
                        </>
                    )}
                </form>

                {/* After successful registration, show a success modal */}
                <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('register.success.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t('register.success.message')}</p>
                        <p>{t('register.success.activationEmail')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseSuccessModal}>
                            {t('register.success.ok')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="text-center mt-3">
                    <a href="/login" className="text-muted">
                        {t('register.links.returnToLogin')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
