import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';
import { FaUserCircle, FaUtensils, FaSignOutAlt, FaLanguage, FaEye } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { MdCategory } from 'react-icons/md';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getTables }  from '../services/api'; // Adjust the import based on your API file location

const RestaurantManagementHeader = () => {
  const navigate = useNavigate();
  const { logout, userRoles } = useContext(AuthContext);
  const { is_owner, is_staff, restaurant_id } = userRoles;
  const { t, i18n } = useTranslation();
  const [tableId, setTableId] = useState(null);
  const [error, setError] = useState('');

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  // Fetch tables when component mounts
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await getTables(restaurant_id);
        const tables = response.data;
        if (tables.length > 0) {
          setTableId(tables[0].id); // Select the first table as default
        } else {
          setError(t('noTablesAvailable')); // Error message if no tables
        }
      } catch (error) {
        setError(t('errorFetchingTables')); // Generic error if API fails
      }
    };
    fetchTables();
  }, [restaurant_id, t]);

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="mb-4"
      style={{ overflow: 'visible' }}
      data-tour="header"
    >
      <Container fluid className="align-items-center" style={{ overflow: 'visible' }}>
        {/* Left Side: Language Selector and Navbar.Brand */}
        <div className="align-items-center">
          <Navbar.Brand
            as={Link}
            to="/dashboard"
            className="fs-4 text-end"
            data-tour="header-brand"
          >
            {t('restaurantManagement')}
          </Navbar.Brand>
          <NavDropdown
            title={
              <>
                <FaLanguage className="me-1" /> {i18n.language.toUpperCase()}
              </>
            }
            id="language-dropdown"
            className="text-white me-3"
            style={{ zIndex: 1050 }}
            menuVariant="dark"
            data-tour="header-language-selector"
          >
            <NavDropdown.Item onClick={() => changeLanguage('en')}>
              🇬🇧 English
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('ar')}>
              🇸🇦 العربية
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('tr')}>
              🇹🇷 Türkçe
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('de')}>
              🇩🇪 Deutsch
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('nl')}>
              🇳🇱 Nederlands
            </NavDropdown.Item>
          </NavDropdown>
        </div>

        <div className="flex-grow-1"></div>

        <div className=" align-items-center">
          <Navbar.Toggle aria-controls="navbar-content" />

          <Navbar.Collapse
            id="navbar-content"
            style={{ position: 'relative', zIndex: 1040, overflow: 'visible' }}
          >
            <Nav className="d-flex align-items-center gap-3">
              {is_owner && (
                <>
                  <Nav.Link
                    as={Link}
                    to={`/restaurant/${restaurant_id}/profile`}
                    className="text-white d-flex align-items-center"
                    data-tour="header-profile"
                  >
                    <FaUserCircle className="me-2" /> {t('profile')}
                  </Nav.Link>

                  {tableId ? (
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/restaurant/${restaurant_id}/table/${tableId}`, '_blank');
                      }}
                      className="text-white d-flex align-items-center"
                      data-tour="header-view-menu"
                    >
                      <FaEye className="me-2" /> {t('viewMenu')}
                    </Nav.Link>
                  ) : (
                    error && <Alert variant="warning">{error}</Alert>
                  )}

                  {/* Updated Manage Menu as a single button */}
                  <Nav.Link
                    as={Link}
                    to={`/restaurant/${restaurant_id}/menus-management`}
                    className="text-white d-flex align-items-center"
                    data-tour="header-manage-menu-button"
                  >
                    <FaUtensils className="me-2" /> {t('manageMenu')}
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to={`/restaurant/${restaurant_id}/staff`}
                    className="text-white d-flex align-items-center"
                    data-tour="header-manage-employees"
                  >
                    <HiUserGroup className="me-2" /> {t('manageEmployees')}
                  </Nav.Link>
                </>
              )}

              {(is_owner || is_staff) && (
                <Button
                  variant="danger"
                  className="d-flex align-items-center"
                  onClick={handleLogout}
                  data-tour="header-logout"
                  aria-label={t('logout')}
                >
                  <FaSignOutAlt className="me-2" /> {t('logout')}
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
};

RestaurantManagementHeader.propTypes = {
  onReplayTour: PropTypes.func,
};

export default RestaurantManagementHeader;
