// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/Header.css';
import { Modal, Button, Form, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createHelpRequest, getTableNumber, getMenus } from '../services/api';
import { useTranslation } from 'react-i18next';
import { FaLanguage } from 'react-icons/fa';
import { BiCart, BiHelpCircle, BiTable, BiDollarCircle } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = ({ totalPrice, basket, restaurantId, tableId, menuId }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const totalItems = basket.reduce((sum, item) => sum + item.quantity, 0);

    const [showHelpModal, setShowHelpModal] = useState(false);
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tableNumber, setTableNumber] = useState(null);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        // Fetch the table number when restaurantId and tableId are available
        const fetchTableNumber = async () => {
            if (restaurantId && tableId) {
                try {
                    const response = await getTableNumber(restaurantId, tableId);
                    setTableNumber(response.data.number); // Assuming response.data.number has the table number
                } catch (error) {
                    console.error('Error fetching table number:', error);
                }
            }
        };

        fetchTableNumber();
    }, [restaurantId, tableId]);

    useEffect(() => {
        // Fetch menus to check if menu exists for selected language
        const fetchMenus = async () => {
            if (restaurantId) {
                try {
                    const response = await getMenus(restaurantId);
                    setMenus(response.data);
                } catch (error) {
                    console.error('Error fetching menus:', error);
                }
            }
        };

        fetchMenus();
    }, [restaurantId]);

    const handleViewBasket = () => {
        if (restaurantId && tableId) {
            navigate(`/restaurant/${restaurantId}/order-basket/${tableId}`);
        } else {
            toast.error(t('header.errors.missingRestaurantOrTable'));
        }
    };

    const handleShowHelpModal = () => setShowHelpModal(true);
    const handleCloseHelpModal = () => {
        setShowHelpModal(false);
        setDescription('');
    };

    const handleSubmitHelpRequest = async (e) => {
        e.preventDefault();

        const trimmedDescription = description.trim();

        const data = {
            restaurant: restaurantId,
            table_number: tableId,
            description: trimmedDescription ? trimmedDescription : null,
        };

        try {
            setIsSubmitting(true);
            const response = await createHelpRequest(data);
            if (response.status === 201) {
                toast.success(t('header.helpRequestSuccess'));
                handleCloseHelpModal();
            } else {
                const errorData = response.data;
                toast.error(t('header.helpRequestFailed', { error: JSON.stringify(errorData) }));
            }
        } catch (error) {
            console.error(t('header.errors.submitHelpRequest'), error);
            toast.error(t('header.helpRequestError'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);

        // After changing language, check if there is a menu for the selected language
        if (menus.length > 0 && restaurantId && tableId) {
            const menuForLanguage = menus.find(menu => menu.language === lng);
            if (menuForLanguage) {
                // Navigate to the menu for the selected language
                navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${menuForLanguage.id}`);
            } else {
                // No menu for the selected language
                toast.error(t('header.errors.noMenuForLanguage'));
            }
        }
    };

    const renderTooltip = (message) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    return (
        <>
            <header
                className="navbar navbar-light bg-white shadow-sm py-2 py-md-3 sticky-top"
                dir={i18n.dir()}
                style={{ overflow: 'visible' }}
            >
                <div
                    className="container-fluid  justify-content-between align-items-center flex-nowrap w-100"
                    style={{ overflow: 'visible', position: 'relative' }}
                >
                    {/* Left Section: Table Number and Total Price */}
                    <div className="d-flex align-items-center flex-shrink-1 me-2">
                        {tableNumber && (
                            <span className="text-muted d-flex align-items-center me-2">
                                <BiTable className="me-1" />
                                <span className="me-1 d-none d-md-inline">{t('header.tableId')}:</span>
                                <strong>{tableNumber}</strong>
                            </span>
                        )}
                        <span className="text-muted d-flex align-items-center">
                            <BiDollarCircle className="me-1" />
                            <span className="me-1 d-none d-md-inline">{t('header.total')}:</span>
                            <strong>${totalPrice.toFixed(2)}</strong>
                        </span>
                    </div>

                    {/* Right Section: View Basket, Help, and Language Selector */}
                    <div className="d-flex  flex-shrink-0" style={{ overflow: 'visible' }}>
                        {/* Language Selector */}
                        <NavDropdown
                            title={
                                <>
                                    <FaLanguage className="me-1" />
                                    <span className="d-none d-sm-inline">{i18n.language.toUpperCase()}</span>
                                </>
                            }
                            id="language-dropdown"
                            className="text-dark me-1"
                            menuVariant="light"
                            style={{ zIndex: 1050 }}
                            drop="down"
                        >
                            <NavDropdown.Item onClick={() => changeLanguage('en')}>
                                🇬🇧 English
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage('ar')}>
                                🇸🇦 العربية
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage('tr')}>
                                🇹🇷 Türkçe
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage('de')}>
                            🇩🇪 Deutsch
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage('nl')}>
                                🇳🇱 Nederlands
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* View Basket Button */}
                        <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(t('header.viewBasketAriaLabel'))}
                        >
                            <button
                                className="btn btn-primary btn-sm px-2 py-1 d-flex align-items-center basket-button me-1"
                                onClick={handleViewBasket}
                                aria-label={t('header.viewBasketAriaLabel')}
                            >
                                <BiCart size={20} className="me-1" />
                                <span className="d-none d-md-inline">{t('header.viewBasket')}</span>
                                <span className="d-inline d-md-none">{`${totalItems}`}</span>
                            </button>
                        </OverlayTrigger>

                        {/* Help Request Button */}
                        <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(t('header.requestHelpAriaLabel'))}
                        >
                            <button
                                className="btn btn-secondary btn-sm px-2 py-1 d-flex align-items-center"
                                onClick={handleShowHelpModal}
                                aria-label={t('header.requestHelpAriaLabel')}
                            >
                                <BiHelpCircle size={20} className="me-1" />
                                <span className="d-none d-md-inline">{t('header.requestHelp')}</span>
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            </header>

            {/* Help Request Modal */}
            <Modal show={showHelpModal} onHide={handleCloseHelpModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('header.helpModal.title')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitHelpRequest}>
                    <Modal.Body>
                        <Form.Group controlId="helpDescription">
                            <Form.Label>{t('header.helpModal.descriptionLabel')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={t('header.helpModal.descriptionPlaceholder')}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseHelpModal}>
                            {t('header.helpModal.cancel')}
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? t('header.helpModal.submitting') : t('header.helpModal.submit')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={i18n.dir() === 'rtl'}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default Header;
