// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Header from './components/Header';
import Layout from './components/Layout';
import RestaurantManagementLayout from './components/RestaurantManagementLayout';
import CustomerMenuPage from './pages/CustomerMenuPage';
import CustomerMenuItemPage from './pages/CustomerMenuItemPage';
import OrderBasketPage from './pages/OrderBasketPage';
import CategoryPage from './pages/CategoryPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import DashboardPage from './pages/DashboardPage';
import OrderPage from './pages/OrderPage';
import CategoryEditPage from './pages/CategoryEditPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import RestaurantProfilePage from './pages/RestaurantProfilePage';
import StaffManagementPage from './pages/StaffManagementPage';
import CategoryManagementPage from './pages/CategoryManagementPage';
import MenuItemManagementPage from './pages/MenuItemManagementPage';
import MenuItemCreatePage from './pages/MenuItemCreatePage';
import MenuItemEditPage from './pages/MenuItemEditPage';
import MenuManagementPage from './pages/MenuManagementPage';
import PasswordResetRequestPage from './pages/PasswordResetRequestPage';
import PasswordResetConfirmPage from './pages/PasswordResetConfirmPage';
import ActivateAccountPage from './pages/ActivateAccountPage';
import UsernameRecoveryPage from './pages/UsernameRecoveryPage';
import MenuSelectionPage from './pages/MenuSelectionPage'; // Import the new component
import { AuthProvider } from './AuthContext';
import PrivateRoute from './components/PrivateRoute';

// Function to retrieve basket from localStorage (optional for persistence)
const loadBasketFromLocalStorage = () => {
    const storedBasket = localStorage.getItem('basket');
    return storedBasket ? JSON.parse(storedBasket) : [];
};

const App = () => {
    const [basketItems, setBasketItems] = useState(loadBasketFromLocalStorage);
    const [totalPrice, setTotalPrice] = useState(0);

    // Calculate total price whenever basketItems changes
    useEffect(() => {
        const newTotalPrice = basketItems.reduce((total, item) => total + item.price * item.quantity, 0);
        setTotalPrice(newTotalPrice);
    }, [basketItems]);

    // Synchronize basket state with localStorage to persist after page refresh
    useEffect(() => {
        localStorage.setItem('basket', JSON.stringify(basketItems));
    }, [basketItems]);

    // Add an item to the basket
    const addToBasket = (item) => {
        setBasketItems((prevItems) => {
            const existingItem = prevItems.find(
                (i) =>
                    i.id === item.id &&
                    JSON.stringify(i.selectedOptions) === JSON.stringify(item.selectedOptions) &&
                    JSON.stringify(i.selectedBooleans) === JSON.stringify(item.selectedBooleans)
            );

            if (existingItem) {
                return prevItems.map((i) =>
                    i.id === item.id &&
                    JSON.stringify(i.selectedOptions) === JSON.stringify(item.selectedOptions) &&
                    JSON.stringify(i.selectedBooleans) === JSON.stringify(item.selectedBooleans)
                        ? { ...i, quantity: i.quantity + item.quantity }
                        : i
                );
            } else {
                return [...prevItems, { ...item, quantity: item.quantity || 1 }];
            }
        });
    };

    // Update the quantity of an item in the basket
    const updateBasketItem = (item, quantity) => {
        if (quantity > 0) {
            setBasketItems((prevItems) =>
                prevItems.map((i) =>
                    i.id === item.id &&
                    JSON.stringify(i.selectedOptions) === JSON.stringify(item.selectedOptions) &&
                    JSON.stringify(i.selectedBooleans) === JSON.stringify(item.selectedBooleans)
                        ? { ...i, quantity: quantity }
                        : i
                )
            );
        }
    };

    // Remove an item from the basket
    const removeBasketItem = (item) => {
        setBasketItems((prevItems) =>
            prevItems.filter(
                (i) =>
                    i.id !== item.id ||
                    JSON.stringify(i.selectedOptions) !== JSON.stringify(item.selectedOptions) ||
                    JSON.stringify(i.selectedBooleans) !== JSON.stringify(item.selectedBooleans)
            )
        );
    };

    // Extract restaurantId, tableId, and menuId from route using useParams
    const HeaderWithRestaurantIdAndTableId = () => {
        const { restaurantId, tableId, menuId } = useParams();
        return (
            <Header
                basket={basketItems}
                totalPrice={totalPrice}
                restaurantId={restaurantId}
                tableId={tableId}
                menuId={menuId}
            />
        );
    };

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Route for Home Page */}
                    <Route path="/" element={<HomePage />} />

                    {/* Auth-related Routes */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/logout" element={<LogoutPage />} />

                    {/* Account Activation Route */}
                    <Route path="/activate/:uidb64/:token/" element={<ActivateAccountPage />} />

                    {/* Password Reset Routes */}
                    <Route path="/password-reset" element={<PasswordResetRequestPage />} />
                    <Route path="/password-reset-confirm/:uidb64/:token/" element={<PasswordResetConfirmPage />} />

                    {/* Username Recovery Route */}
                    <Route path="/username-recovery" element={<UsernameRecoveryPage />} />

                    {/* Authenticated Routes */}
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <DashboardPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/orders/:restaurantId"
                        element={
                            <PrivateRoute>
                                <OrderPage />
                            </PrivateRoute>
                        }
                    />

                    {/* Restaurant management routes for authenticated users */}
                    <Route
                        path="/restaurant/:restaurantId/*"
                        element={
                            <PrivateRoute>
                                <RestaurantManagementLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route path="profile" element={<RestaurantProfilePage />} />
                        {/* Menu Management */}
                        <Route path="menus-management" element={<MenuManagementPage />} />
                        {/* Nested Routes for Menu Management */}
                        <Route
                            path="menus-management/:menuId/manage-categories"
                            element={<CategoryManagementPage />}
                        />
                        <Route
                            path="menus-management/:menuId/manage-menu-items"
                            element={<MenuItemManagementPage />}
                        />
                        {/* Other Routes */}
                        <Route path="staff" element={<StaffManagementPage />} />
                        {/* Menu Item management routes */}
                        <Route path="menu-items" element={<MenuItemManagementPage />} />
                        <Route path="menu-items/create" element={<MenuItemCreatePage />} />
                        <Route path="menu-items/:menuItemId/edit" element={<MenuItemEditPage />} />
                    </Route>

                    {/* Public (Non-Authenticated) Customer Routes */}

                    {/* New Route for Menu Selection */}
                    <Route
                        path="/restaurant/:restaurantId/table/:tableId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <MenuSelectionPage />
                            </Layout>
                        }
                    />

                    <Route
                        path="/restaurant/:restaurantId/table/:tableId/menu/:menuId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <CustomerMenuPage basketItems={basketItems} addToBasket={addToBasket} />
                            </Layout>
                        }
                    />
                    <Route
                        path="/restaurant/:restaurantId/table/:tableId/menu/:menuId/menu-item/:itemId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <CustomerMenuItemPage addToBasket={addToBasket} />
                            </Layout>
                        }
                    />
                    <Route
                        path="/restaurant/:restaurantId/order-basket/:tableId/menu/:menuId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <OrderBasketPage
                                    basketItems={basketItems}
                                    updateBasketItem={updateBasketItem}
                                    removeBasketItem={removeBasketItem}
                                    setBasketItems={setBasketItems}
                                />
                            </Layout>
                        }
                    />
                    <Route
                        path="/restaurant/:restaurantId/table/:tableId/menu/:menuId/category/:categoryId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <CategoryPage addToBasket={addToBasket} />
                            </Layout>
                        }
                    />
                    <Route
                        path="/restaurant/:restaurantId/order-success/:tableId/menu/:menuId"
                        element={
                            <Layout>
                                <HeaderWithRestaurantIdAndTableId />
                                <OrderSuccessPage />
                            </Layout>
                        }
                    />

                    {/* Redirect unknown routes to Home Page */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
