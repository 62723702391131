// src/components/StatusIcon.js

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUtensils,      // Pending
    faFire,          // In Progress
    faCheckCircle,   // Completed
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './StatusIcon.css'; // Custom styles for the status icon

const StatusIcon = ({ status }) => {
    const { t } = useTranslation();

    // Define the mapping between status and their respective icons, colors, and messages
    const STATUS_MAPPING = {
        'Pending': {
            icon: faUtensils,
            color: '#FFA500', // Orange
            message: t('orderSuccessPage.status.pendingMessage'),
        },
        'In Progress': {
            icon: faFire,
            color: '#007BFF', // Blue
            message: t('orderSuccessPage.status.inProgressMessage'),
        },
        'Completed': {
            icon: faCheckCircle,
            color: '#28A745', // Green
            message: t('orderSuccessPage.status.completedMessage'),
        },
    };

    // Fallback for unknown statuses
    const currentStatus = STATUS_MAPPING[status] || {
        icon: faUtensils,
        color: '#FFA500',
        message: t('orderSuccessPage.status.unknownStatusMessage'),
    };

    return (
        <div className="status-icon d-flex flex-column align-items-center">
            <FontAwesomeIcon
                icon={currentStatus.icon}
                size="4x"
                color={currentStatus.color}
                className="status-icon-main fade-in"
                aria-label={t(`orderSuccessPage.status.${status.toLowerCase()}Label`)}
            />
            <p className="status-message mt-3">{currentStatus.message}</p>
        </div>
    );
};

StatusIcon.propTypes = {
    status: PropTypes.oneOf(['Pending', 'In Progress', 'Completed']).isRequired,
};

export default StatusIcon;
