import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getOrderStatus } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OrderSuccessPage.css'; // Custom styles for this page
import TicTacToe from '../components/TicTacToe';
import StatusIcon from '../components/StatusIcon';
import { useTranslation } from 'react-i18next';
import { Container, Button, Alert, Spinner, ListGroup, Row, Col } from 'react-bootstrap';

const OrderSuccessPage = () => {
    const { t, i18n } = useTranslation();
    const { restaurantId, tableId } = useParams();
    const navigate = useNavigate();
    const [orderStatus, setOrderStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentStatus, setCurrentStatus] = useState('Initial');

    // Function to fetch the order status
    const fetchOrderStatus = async () => {
        try {
            const response = await getOrderStatus(restaurantId, tableId);
            if (response && response.length > 0) {
                setOrderStatus(response[0]);
                setCurrentStatus(response[0].status);
                setError(null);
            } else {
                setError(t('orderSuccessPage.errors.noOrdersForTable'));
                setOrderStatus(null);
            }
        } catch (error) {
            setError(t('orderSuccessPage.errors.fetchOrderStatus'));
            setOrderStatus(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderStatus();
        const interval = setInterval(() => {
            fetchOrderStatus();
        }, 10000);
        return () => clearInterval(interval);
    }, [restaurantId, tableId, t]);

    const handleOrderAgain = () => {
        navigate(`/restaurant/${restaurantId}/table/${tableId}`);
    };

    if (loading) {
        return (
            <Container className="mt-5 text-center">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">{t('orderSuccessPage.loading')}</span>
                </Spinner>
                <p className="mt-3">{t('orderSuccessPage.loading')}</p>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-5 text-center">
                <Alert variant="danger">
                    {error}
                </Alert>
            </Container>
        );
    }

    return (
        <Container className="mt-4 order-success-page" dir={i18n.dir()}>
            <div className="order-success-content text-center">
                <StatusIcon status={currentStatus} />
                
                {/* Tic-Tac-Toe Game placed before the order summary */}
                <div className="mt-4">
                    <TicTacToe />
                </div>

                {orderStatus && orderStatus.items ? (
                    <>
                        <div className="order-summary mt-4">
                            <h4 className="text-center">{t('orderSuccessPage.orderSummary')}</h4>
                            <ListGroup variant="flush">
                                {orderStatus.items.map((item, index) => (
                                    <ListGroup.Item key={index} className="text-center p-2">
                                        <Row className="justify-content-center">
                                            <Col xs={12}>
                                                <strong>{item.quantity}x {item.menu_item}</strong>
                                            </Col>
                                            {item.special_request && (
                                                <Col xs={12}>
                                                    <span className="text-muted small">
                                                        {t('orderSuccessPage.specialRequest')}: {item.special_request}
                                                    </span>
                                                </Col>
                                            )}
                                        </Row>
                                        {item.selected_options && item.selected_options.length > 0 && (
                                            <ul className="options-list mb-0 mt-1 text-center" style={{ paddingLeft: '0' }}>
                                                {item.selected_options.map((option, idx) => (
                                                    <li key={idx} className="small">
                                                        {option.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>

                        <div className="action-buttons mt-4">
                            <Button variant="primary" size="lg" onClick={handleOrderAgain}>
                                <FontAwesomeIcon icon={faRedo} /> {t('orderSuccessPage.orderAgain')}
                            </Button>
                        </div>
                    </>
                ) : (
                    <p>{t('orderSuccessPage.noOrderDetails')}</p>
                )}
            </div>
        </Container>
    );
};

export default OrderSuccessPage;
