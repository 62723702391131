// src/pages/CategoryPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMenuItemsByCategory, getRestaurantPublicDetails } from '../services/api';
import Footer from '../components/Footer';
import '../styles/Footer.css';
import '../styles/CategoryPage.css'; // Ensure this CSS file exists and contains necessary styles
import '../App.css';
import { Container, Row, Col, Card, Button, Spinner, Alert, Badge } from 'react-bootstrap';
import { FaReceipt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const CategoryPage = ({ addToBasket }) => {
    const { t } = useTranslation();
    const { restaurantId, categoryId, tableId, menuId } = useParams(); // Include menuId
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [restaurantBackground, setRestaurantBackground] = useState(null);
    const [restaurantName, setRestaurantName] = useState('');

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                // Fetch restaurant details
                const restaurantResponse = await getRestaurantPublicDetails(restaurantId);
                const restaurantData = restaurantResponse.data;
                setRestaurantBackground(restaurantData.background_image_url);
                setRestaurantName(restaurantData.name);

                // Fetch menu items for the category
                console.log('Fetching menu items for category:', categoryId);
                const menuResponse = await getMenuItemsByCategory(restaurantId, menuId, categoryId);
                console.log('Menu items fetched:', menuResponse.data);
                setMenuItems(menuResponse.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching menu items or restaurant details:', err);
                setError(t('categoryPage.errors.fetchFailed'));
                setLoading(false);
            }
        };

        if (restaurantId && menuId && categoryId) {
            fetchCategoryData();
        } else {
            setError(t('categoryPage.errors.invalidIds'));
            setLoading(false);
        }
    }, [restaurantId, menuId, categoryId, t]);

    // Handle clicking on a menu item
    const handleMenuItemClick = (itemId, isAvailable) => {
        if (isAvailable) {
            navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${menuId}/menu-item/${itemId}`);
        }
    };

    if (loading)
        return (
            <Container className="my-5 text-center">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">{t('categoryPage.loading')}</span>
                </Spinner>
                <p className="mt-3">{t('categoryPage.loading')}</p>
            </Container>
        );

    if (error)
        return (
            <Container className="my-5">
                <Alert variant="danger" className="d-flex align-items-center">
                    <FaReceipt className="me-2" />
                    {error}
                </Alert>
            </Container>
        );

    return (
        <div className="category-page-container" style={{ position: 'relative', paddingBottom: '100px' }}>
            {/* Blurred Background Image */}
            {restaurantBackground && (
                <div
                    className="background-image"
                    style={{
                        backgroundImage: `url(${restaurantBackground})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        filter: 'blur(4px)',
                        zIndex: -1,
                    }}
                    aria-label={t('categoryPage.aria.backgroundImage')}
                />
            )}

            {/* Foreground Content */}
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col lg={10} md={12}>
                        <h1 className="mb-4">{t('categoryPage.title', { restaurantName })}</h1>
                        <Row>
                            {menuItems.length > 0 ? (
                                menuItems.map((item) => (
                                    <Col key={item.id} xs={6} sm={6} md={4} lg={3} className="mb-4">
                                        <Card
                                            className={`h-100 shadow-sm menu-item-card ${
                                                !item.is_available ? 'unavailable' : ''
                                            }`}
                                            onClick={() => handleMenuItemClick(item.id, item.is_available)}
                                            style={{ cursor: item.is_available ? 'pointer' : 'not-allowed' }}
                                        >
                                            {item.image && (
                                                <div className="image-container">
                                                    <Card.Img
                                                        variant="top"
                                                        src={item.image}
                                                        alt={item.name}
                                                        style={{ height: '150px', objectFit: 'cover' }}
                                                    />
                                                    {!item.is_available && (
                                                        <Badge
                                                            bg="danger"
                                                            className="unavailable-badge"
                                                            aria-label={t('categoryPage.unavailable')}
                                                        >
                                                            {t('categoryPage.temporarilyUnavailable')}
                                                        </Badge>
                                                    )}
                                                </div>
                                            )}
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>{item.name}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <Col>
                                    <Alert variant="info" className="text-center">
                                        <FaReceipt className="me-2" />
                                        {t('categoryPage.noItems')}
                                    </Alert>
                                </Col>
                            )}
                        </Row>
                        <Button variant="secondary" onClick={() => navigate(-1)} className="mt-3">
                            {t('categoryPage.backButton')}
                        </Button>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default CategoryPage;
