import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// List of supported languages
const supportedLanguages = ['en', 'ar', 'nl', 'de'];

// Helper function to set the direction (RTL or LTR)
const setDirection = (lang) => {
  const rtlLanguages = ['ar']; // List of RTL languages
  const isRtl = rtlLanguages.includes(lang);
  const dir = isRtl ? 'rtl' : 'ltr'; // RTL for Arabic, LTR for others

  document.documentElement.setAttribute('dir', dir); // Sets the dir attribute
  document.documentElement.setAttribute('lang', lang); // Sets the lang attribute
};

// Function to determine the initial language
const getInitialLanguage = () => {
  const storedLanguage = localStorage.getItem('language');
  if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
    return storedLanguage; // Returns the stored language if available
  }
  // If no stored language is available, use the device or browser language
  const deviceLanguage = window.navigator.language || 'en'; // Default to 'en' if detection fails
  const langCode = deviceLanguage.split('-')[0]; // Use the language part (e.g., 'en' from 'en-US')
  return supportedLanguages.includes(langCode) ? langCode : 'en'; // Use only supported languages
};

i18n
  .use(Backend) // Loads translations via HTTP (default public/assets/locales)
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes the i18n instance to react-i18next
  .init({
    fallbackLng: 'en', // Fallback to English if no language is detected
    debug: true,
    lng: getInitialLanguage(), // Uses the initial language from the device or stored value
    supportedLngs: supportedLanguages, // Defines the supported languages
    interpolation: {
      escapeValue: false, // React already handles XSS protection
    },
    backend: {
      loadPath: '/static/locales/{{lng}}/{{ns}}.json', // Path to the translation files
    },
    detection: {
      // Detection order (first localStorage, then navigator)
      order: ['localStorage', 'navigator'],
      // Keys or parameters to lookup language from
      lookupLocalStorage: 'language',
      // Cache user language
      caches: ['localStorage'],
      // Use browser/device language as a fallback if no stored language
      lookupNavigator: true,
    },
    react: {
      useSuspense: false, // Can be adjusted based on your use case
    },
  });

// Event listener for language change to update direction and storage
i18n.on('languageChanged', (lang) => {
  setDirection(lang);
  if (supportedLanguages.includes(lang)) {
    localStorage.setItem('language', lang); // Stores the chosen language in localStorage
  }
});

// Initially set the direction based on the detected language
const detectedLanguage = getInitialLanguage();
setDirection(detectedLanguage);

export default i18n;
