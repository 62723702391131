// src/pages/DashboardPage.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import * as Tone from 'tone'; // Import Tone.js

import { 
    getTables, 
    addTable, 
    removeTable, 
    getOrders, 
    fetchHelpRequests, 
    acceptHelpRequest, 
    declineHelpRequest, 
    deleteHelpRequest,
    downloadQrCode
} from '../services/api';
import RestaurantManagementHeader from '../components/RestaurantManagementHeader';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import './DashboardPage.css'; 
import { useTranslation } from 'react-i18next';

import { MdTableRestaurant } from 'react-icons/md'; // Importing Material Icons' dining table
import { Modal, Button, Form } from 'react-bootstrap';
import Joyride, { STATUS } from 'react-joyride'; // Imported Joyride

const DashboardPage = () => {
    const { t } = useTranslation();
    const [userRole, setUserRole] = useState('');
    const [restaurantId, setRestaurantId] = useState(null);
    const [tables, setTables] = useState([]); // State to manage tables
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [helpRequests, setHelpRequests] = useState([]);
    const [error, setError] = useState(null);
    const [restaurantName, setRestaurantName] = useState(''); 
    const [username, setUsername] = useState('');
    const [showHelpDetailsModal, setShowHelpDetailsModal] = useState(false);
    const [selectedHelpRequest, setSelectedHelpRequest] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [currentAction, setCurrentAction] = useState(null); // 'accept' or 'decline'
    const [currentHelpRequestId, setCurrentHelpRequestId] = useState(null);
    const [responseText, setResponseText] = useState('');

    // Add Table Modal state
    const [showAddTableModal, setShowAddTableModal] = useState(false);
    const [newTableNumber, setNewTableNumber] = useState('');
    const [newTableCapacity, setNewTableCapacity] = useState(4);

    // Tour state
    const [runTour, setRunTour] = useState(false);

    // Notification state
    const [notificationEnabled, setNotificationEnabled] = useState(true); // New state for notifications

    const navigate = useNavigate();
    const { restaurantId: routeRestaurantId } = useParams(); // Assuming restaurantId is part of the route

    // Ref to store previous order IDs
    const prevOrderIds = useRef(new Set());

    // Define tour steps
    const steps = [
        {
            target: '[data-tour="header"]',
            content: t('tour.header'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-logout"]',
            content: t('tour.headerLogout'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-manage-employees"]',
            content: t('tour.headerManageEmployees'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-manage-menu"]',
            content: t('tour.headerManageMenu'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-manage-categories"]',
            content: t('tour.headerManageCategories'),
            placement: 'right',
        },
        {
            target: '[data-tour="header-manage-menu-items"]',
            content: t('tour.headerManageMenuItems'),
            placement: 'right',
        },
        {
            target: '[data-tour="header-profile"]',
            content: t('tour.headerProfile'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-language-selector"]',
            content: t('tour.headerLanguageSelector'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-brand"]',
            content: t('tour.headerBrand'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="header-view-menu"]',
            content: t('tour.headerViewMenu'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="completed-orders-card"]',
            content: t('tour.completedOrdersCard'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="in-progress-orders-card"]',
            content: t('tour.inProgressOrdersCard'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="pending-orders-card"]',
            content: t('tour.pendingOrdersCard'),
            placement: 'bottom',
        },
        {
            target: '[data-tour="help-request-section"]',
            content: t('tour.helpRequestSection'),
            placement: 'right',
        },
        {
            target: '[data-tour="help-request-item"]',
            content: t('tour.helpRequestItem'),
            placement: 'right',
        },
        {
            target: '[data-tour="add-table-button"]',
            content: t('tour.addTableButton'),
            placement: 'top',
        },
        {
            target: '[data-tour="table-card"]',
            content: t('tour.tableCard'),
            placement: 'top',
        },
        {
            target: '[data-tour="recent-orders-table"]',
            content: t('tour.recentOrdersTable'),
            placement: 'top',
        },
        {
            target: '[data-tour="notification-toggle"]',
            content: t('tour.notificationToggle'),
            placement: 'bottom',
        },
    ];

    // Handle Joyride callbacks
    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunTour(false);
            localStorage.setItem('hasSeenTour', true);
        }
    };

    // Check if the user has already completed the tour
    useEffect(() => {
        const hasSeenTour = localStorage.getItem('hasSeenTour');
        if (!hasSeenTour) {
            setRunTour(true);
        }
    }, []);

    // Helper function to determine badge color based on status
    const getBadgeColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'danger';
            case 'Accepted':
                return 'primary';
            case 'Resolved':
                return 'success';
            case 'Declined':
                return 'secondary';
            default:
                return 'secondary';
        }
    };

    // Decode the token and set the user state
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const { restaurant_id, is_owner, is_staff, username, restaurant_name } = decodedToken;

                setUsername(username);
                setRestaurantName(restaurant_name);
                setRestaurantId(restaurant_id || routeRestaurantId); // Use routeRestaurantId if available

                if (is_owner) setUserRole('owner');
                else if (is_staff) setUserRole('staff');
                else navigate('/login');

            } catch (error) { 
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate, routeRestaurantId]);

    // Define fetch functions using useCallback to include them as dependencies
    const fetchTablesCallback = useCallback(async (restaurantId) => {
        try {
            setLoading(true);
            const response = await getTables(restaurantId);
            console.log('Tables Data:', response.data); // Debugging
            setTables(response.data); // Assuming response.data is an array of tables
            setLoading(false);
        } catch (error) {
            setError(t('errors.loadDataFailed'));
            setLoading(false);
        }
    }, [t]);


    const fetchOrdersCallback = useCallback(async (restaurantId) => {
        try {
            console.log("Fetching orders...");
            const response = await getOrders(restaurantId);
            console.log("Orders Data:", response.data);
            setOrders(response.data || []);
        } catch (error) {
            console.error(t('errors.fetchOrdersError'), error);
        }
    }, [t]);

    const loadHelpRequestsCallback = useCallback(async (restaurantId) => {
        try {
            const response = await fetchHelpRequests(restaurantId);
            setHelpRequests(response.data || []);
        } catch (error) {
            console.error(t('errors.fetchHelpRequestsError'), error);
        }
    }, [t]);

    // Fetch Tables, Orders, and Help Requests when restaurantId is available
    useEffect(() => {
        if (restaurantId) {
            fetchTablesCallback(restaurantId);
            fetchOrdersCallback(restaurantId);
            loadHelpRequestsCallback(restaurantId);
        }
    }, [restaurantId, fetchTablesCallback, fetchOrdersCallback, loadHelpRequestsCallback]);

    // Get the number of orders in each status
    const getOrderCountByStatus = (status) => {
        return orders.filter(order => order.status === status).length;
    };

    /**
     * Updated Functions: Use table.id instead of table.number
     */

    // Check if the table has a new pending order
    const isTableBouncing = (tableId) => {
        return orders.some(order => order.table === tableId && order.status === 'Pending');
    };

    // Get the color for the table's status
    const getTableStatusColor = (tableId) => {
        const order = orders.find(order => order.table === tableId);
        switch (order?.status) {
            case 'Pending':
                return '#dc3545'; // Bootstrap 'danger' color
            case 'In Progress':
                return '#ffc107'; // Bootstrap 'warning' color
            case 'Completed':
                return '#28a745'; // Bootstrap 'success' color
            default:
                return '#6c757d'; // Bootstrap 'secondary' color
        }
    };

    // Handle adding a table (opens the modal)
    const handleAddTable = () => {
        setShowAddTableModal(true);
    };

    // Handle submitting the new table
    const handleSubmitAddTable = async (e) => {
        e.preventDefault();
        if (!newTableNumber || !newTableCapacity) {
            alert(t('addTable.validationError'));
            return;
        }

        try {
            const tableData = {
                number: parseInt(newTableNumber),
                capacity: parseInt(newTableCapacity),
            };
            await addTable(restaurantId, tableData);
            alert(t('addTable.success'));
            setShowAddTableModal(false);
            setNewTableNumber('');
            setNewTableCapacity(4);
            fetchTablesCallback(restaurantId);
        } catch (error) {
            console.error(t('errors.addTableError'), error);
            alert(`${t('errors.addTableError')}: ${error.response?.data?.error || error.message}`);
        }
    };

    // Handle deleting a table
    const handleDeleteTable = async (tableId) => {
        if (window.confirm(t('removeTable.confirmation'))) {
            try {
                await removeTable(restaurantId, tableId);
                alert(t('removeTable.success'));
                fetchTablesCallback(restaurantId);
            } catch (error) {
                console.error(t('errors.removeTableError'), error);
                alert(`${t('errors.removeTableError')}: ${error.response?.data?.error || error.message}`);
            }
        }
    };

    // Handle deleting a help request
    const handleDeleteHelpRequest = async (helpRequestId) => {
        if (window.confirm(t('helpRequest.deleteConfirmation'))) {
            try {
                await deleteHelpRequest(helpRequestId);
                alert(t('helpRequest.deleted'));
                loadHelpRequestsCallback(restaurantId);
            } catch (error) {
                console.error(t('errors.deleteHelpRequestError'), error);
                alert(`${t('errors.deleteHelpRequestError')}: ${error.response?.data?.error || error.message}`);
            }
        }
    };

    // New handler to respond to help requests (Accept or Decline)
    const handleRespondHelpRequest = (helpRequest, action) => {
        setSelectedHelpRequest(helpRequest);
        setCurrentHelpRequestId(helpRequest.id);
        setCurrentAction(action); // 'accept' or 'decline'
        setResponseText(''); // Clear previous response
        setShowResponseModal(true);
    };

    // Handle submitting the response
    const handleSubmitResponse = async () => {
        if (!responseText.trim()) {
            alert(t('helpRequest.enterResponseValidation'));
            return;
        }

        try {
            if (currentAction === 'accept') {
                await acceptHelpRequest(currentHelpRequestId, responseText);
                alert(t('helpRequest.accepted'));
            } else if (currentAction === 'decline') {
                await declineHelpRequest(currentHelpRequestId, responseText);
                alert(t('helpRequest.declined'));
            }
            setShowResponseModal(false);
            setResponseText('');
            loadHelpRequestsCallback(restaurantId);
        } catch (error) {
            console.error(`Error during ${currentAction}ing help request:`, error);
            alert(`${t('errors.respondHelpRequestError')}: ${error.response?.data?.error || error.message}`);
        }
    };

    // Memoized filtered help requests (exclude accepted and older than 2 hours)
    const filteredHelpRequests = useMemo(() => {
        const twoHoursAgo = new Date(Date.now() - 2 * 60 * 60 * 1000); // Current time minus 2 hours
        return helpRequests.filter(request => {
            if (request.status === 'Accepted') {
                const createdAt = new Date(request.created_at);
                return createdAt >= twoHoursAgo; // Include only if created within the last 2 hours
            }
            return true; // Include all other requests
        });
    }, [helpRequests]);

    // Function to handle stronger notification ringtone for new orders using Tone.js
    const playRingtone = async () => {
        try {
            await Tone.start(); // Required to start the audio context

            // Create a Gain node for controlled volume
            const gainNode = new Tone.Gain(0.7).toDestination();

            // Use a MonoSynth for a clear, sharp notification sound
            const monoSynth = new Tone.MonoSynth({
                oscillator: { type: "square" }, // Square wave for a bright tone
                envelope: {
                    attack: 0.01,
                    decay: 0.3,
                    sustain: 0.2,
                    release: 0.1,
                },
            }).connect(gainNode);

            // Define a quick, repeating melody
            const now = Tone.now();
            monoSynth.triggerAttackRelease("C6", "16n", now);
            monoSynth.triggerAttackRelease("G5", "16n", now + 0.15);
            monoSynth.triggerAttackRelease("C6", "16n", now + 0.3);
            monoSynth.triggerAttackRelease("E6", "16n", now + 0.45); // Adds a slight higher pitch for variety
            monoSynth.triggerAttackRelease("C6", "16n", now + 0.6); // Repeats main note

            // Optional: Add a slight delay for a resonant effect
            const feedbackDelay = new Tone.FeedbackDelay("8n", 0.2).toDestination();
            monoSynth.connect(feedbackDelay);
        } catch (error) {
            console.error("Error playing notification ringtone:", error);
        }
    };

// Periodic order checking effect with ringtone notifications
useEffect(() => {
    // Initialize previous order IDs
    prevOrderIds.current = new Set(orders.map(order => order.id));

    // Function to check for new orders
    const checkForNewOrders = async () => {
        try {
            const response = await getOrders(restaurantId);
            const latestOrders = response.data || [];

            // Extract current order IDs
            const latestOrderIds = new Set(latestOrders.map(order => order.id));

            // Determine new orders by checking IDs not present in prevOrderIds
            const newOrders = latestOrders.filter(order => !prevOrderIds.current.has(order.id));

            // Check if any new orders have status 'Pending'
            const hasNewPendingOrders = newOrders.some(order => order.status === 'Pending');

            if (hasNewPendingOrders && notificationEnabled) {
                playRingtone(); // Play the ringtone only for new pending orders
            }

            // Update orders state
            setOrders(latestOrders);

            // Update prevOrderIds
            prevOrderIds.current = latestOrderIds;
        } catch (error) {
            console.error(t('errors.fetchOrdersError'), error);
        }
    };

    // Set up interval
    const intervalId = setInterval(() => {
        if (notificationEnabled) {
            checkForNewOrders();
        }
    }, 4000); // 4 seconds

    // Cleanup on unmount
    return () => clearInterval(intervalId);
}, [restaurantId, notificationEnabled, t]); // Removed 'orders' from dependencies to prevent infinite loops


    // Initialize notificationEnabled from localStorage
    useEffect(() => {
        const storedNotification = localStorage.getItem('notificationEnabled');
        if (storedNotification !== null) {
            setNotificationEnabled(storedNotification === 'true');
        }
    }, []);

    // Update localStorage whenever notificationEnabled changes
    useEffect(() => {
        localStorage.setItem('notificationEnabled', notificationEnabled);
    }, [notificationEnabled]);

    // Render loading state
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">{t('loading')}</span>
                </div>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <div className="alert alert-danger text-center" role="alert">
                {error}
            </div>
        );
    }

    // Function to handle opening help request details
    const handleOpenHelpDetails = (helpRequest) => {
        setSelectedHelpRequest(helpRequest);
        setCurrentHelpRequestId(helpRequest.id);
        setShowHelpDetailsModal(true);
    };

    // Function to handle closing help request details
    const handleCloseHelpDetails = () => {
        setSelectedHelpRequest(null);
        setShowHelpDetailsModal(false);
    };


    const handleDownloadQrCode = async (table) => {
        // Open a blank window immediately
        const qrWindow = window.open('', '_blank');
    
        try {
            const response = await downloadQrCode(restaurantId, table.id);
    
            // Assuming response.data contains JSON with qr_code_path
            const data = response.data;
    
            // If response is a blob, parse it accordingly
            // This depends on how your API sends the data
            let qrCodeUrl;
            if (data.qr_code_path) {
                qrCodeUrl = data.qr_code_path;
            } else {
                // Handle different response types if necessary
                const text = await data.text();
                const jsonData = JSON.parse(text);
                qrCodeUrl = jsonData.qr_code_path;
            }
    
            if (qrCodeUrl) {
                // Redirect the blank window to the QR code URL
                qrWindow.location.href = qrCodeUrl;
            } else {
                qrWindow.close(); // Close the window if URL is not found
                console.error("QR code path not found in the response.");
                alert(t('errors.qrCodeNotFound'));
            }
        } catch (error) {
            qrWindow.close(); // Close the window on error
            console.error("Error processing QR code:", error);
            alert(`${t('errors.downloadQrFailed')}: ${error.response?.data?.error || error.message}`);
        }
    };
    
    
    // Helper function to get table number by table ID
    const getTableNumberById = (tableId) => {
        const table = tables.find(t => t.id === tableId);
        return table ? table.number : 'N/A';
    };

    return (
        <div className="dashboard-page">
            {/* React Joyride */}
            <Joyride
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                run={runTour}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />

            {/* Restaurant management header */}
            <RestaurantManagementHeader />

            <div className="container mt-5" dir="rtl">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h1 className="mb-0"><i className="bi bi-shop-window"></i> {restaurantName}</h1>
                    
                    {/* Notification Toggle */}
                    <Form>
                        <Form.Check 
                            type="switch"
                            id="notification-toggle"
                            label={t('notifications.enable')}
                            checked={notificationEnabled}
                            onChange={(e) => setNotificationEnabled(e.target.checked)}
                            data-tour="notification-toggle" // Added data-tour attribute for Joyride
                        />
                    </Form>
                </div>

                {/* Order statistics */}
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div 
                            className="card text-white bg-success mb-3 shadow-sm cursor-pointer" 
                            onClick={() => navigate(`/orders/${restaurantId}?status=Completed`)}
                            data-tour="completed-orders-card" // Added data-tour attribute
                        >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-check-circle-fill fs-3 me-3"></i>
                                    <div>
                                        <h5 className="card-title">{t('orders.completed')}</h5>
                                        <p className="card-text display-6">{getOrderCountByStatus('Completed')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div 
                            className="card text-white bg-warning mb-3 shadow-sm cursor-pointer" 
                            onClick={() => navigate(`/orders/${restaurantId}?status=In Progress`)}
                            data-tour="in-progress-orders-card" // Added data-tour attribute
                        >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-hourglass-split fs-3 me-3"></i>
                                    <div>
                                        <h5 className="card-title">{t('orders.inProgress')}</h5>
                                        <p className="card-text display-6">{getOrderCountByStatus('In Progress')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div 
                            className="card text-white bg-danger mb-3 shadow-sm cursor-pointer" 
                            onClick={() => navigate(`/orders/${restaurantId}?status=Pending`)}
                            data-tour="pending-orders-card" // Added data-tour attribute
                        >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-exclamation-triangle-fill fs-3 me-3"></i>
                                    <div>
                                        <h5 className="card-title">{t('orders.pending')}</h5>
                                        <p className="card-text display-6">{getOrderCountByStatus('Pending')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Help Requests Section */}
                <div className="mb-5">
                    <h2 data-tour="help-request-section"><i className="bi bi-question-circle fs-4 me-2"></i> {t('helpRequests')}</h2>
                    {filteredHelpRequests.length > 0 ? (
                        <div className="list-group mt-3">
                            {filteredHelpRequests.map((request) => (
                                <div key={request.id} className="list-group-item" data-tour="help-request-item">
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <div 
                                            onClick={() => handleOpenHelpDetails(request)} 
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <strong>{t('tableNumber')}: {getTableNumberById(request.table)}</strong>
                                            <p className="mb-1">
                                                {request.description 
                                                    ? (request.description.length > 50 
                                                        ? `${request.description.substring(0, 50)}...` 
                                                        : request.description)
                                                    : t('noDescription')}
                                            </p>
                                        </div>
                                        <div>
                                            <span className={`badge bg-${getBadgeColor(request.status)}`}>
                                                {t(`statuses.${request.status.toLowerCase()}`)}
                                            </span>
                                            {(userRole === 'owner' || userRole === 'staff') && request.status === 'Pending' ? (
                                                <div className="mt-2 d-flex gap-2">
                                                    <Button 
                                                        variant="success" 
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            handleRespondHelpRequest(request, 'accept');
                                                        }}
                                                    >
                                                        {t('accept')}
                                                    </Button>
                                                    <Button 
                                                        variant="danger" 
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            handleRespondHelpRequest(request, 'decline');
                                                        }}
                                                    >
                                                        {t('decline')}
                                                    </Button>
                                                    <Button 
                                                        variant="secondary" 
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            handleDeleteHelpRequest(request.id);
                                                        }}
                                                    >
                                                        {t('delete')}
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="alert alert-info text-center mt-3" role="alert">
                            {t('helpRequest.noHelpRequests')}
                        </div>
                    )}
                </div>

                {/* Table management */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="mb-0" data-tour="tables-section"><i className="bi bi-table fs-4 me-2"></i> {t('tables')}</h2>
                    {userRole === 'owner' && (
                        <div className="d-flex gap-2">
                            <button className="btn btn-primary flex-fill" onClick={handleAddTable} data-tour="add-table-button">
                                <i className="bi bi-plus-circle me-1"></i> {t('addTable.addtablebutton')}
                            </button>
                        </div>
                    )}
                </div>

                {/* Render tables */}
                <div className="row">
                    {tables.length > 0 ? (
                        tables.map((table) => {
                            const statusColor = getTableStatusColor(table.id);
                            const hasPendingOrder = isTableBouncing(table.id);
                            const hasHelpRequest = helpRequests.some(hr => hr.table === table.id && hr.status === 'Pending');

                            return (
                                <div 
                                    className={`col-md-3 mb-4 ${hasPendingOrder ? 'bouncing' : ''}`} 
                                    key={table.id}
                                    onClick={() => (hasPendingOrder || hasHelpRequest) && navigate(`/orders/${restaurantId}`)} 
                                    style={{ cursor: (hasPendingOrder || hasHelpRequest) ? 'pointer' : 'default' }}
                                    data-tour="table-card"
                                >
                                    <div className="card h-100 shadow-sm position-relative">
                                        {/* Status color circle */}
                                        <div
                                            className="status-circle"
                                            style={{ backgroundColor: statusColor }}
                                            title={`${t('tableStatus')}: ${t(`statuses.${orders.find(order => order.table === table.id)?.status.toLowerCase()}`) || t('statuses.empty')}`}
                                        ></div>

                                        {/* Help Request Indicator */}
                                        {hasHelpRequest && (
                                            <div 
                                                className="help-request-indicator position-absolute"
                                                title={t('helpRequest.indicatorTitle')}
                                                style={{ top: '10px', right: '10px' }}
                                            >
                                                <i className="bi bi-question-circle-fill text-warning"></i>
                                            </div>
                                        )}

                                        <div className="card-body text-center">
                                            <h5 className="card-title mb-4">{t('table')} {table.number}</h5>
                                            

                                            {/* Table Icon and Buttons Section */}
                                                <div className="table-qr-section text-center">
                                                {/* Replace QR Code Image with Table Icon */}
                                                <div className="table-icon mb-3" data-tour="table-icon">
                                                    <MdTableRestaurant className="dining-table-icon" size={48} color="#8B4513" />
                                                </div>

                                                {/* Buttons Container */}
                                                <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                                                    {/* Download Button */}
                                                    <Button 
                                                        variant="outline-dark" 
                                                        size="sm" 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            handleDownloadQrCode(table);
                                                        }}
                                                        className="me-sm-2 mb-2 mb-sm-0"
                                                        data-tour="download-qr-button"
                                                    >
                                                        <i className="bi bi-download me-2"></i> {t('downloadQr')}
                                                    </Button>

                                                    {/* Delete Button */}
                                                    {userRole === 'owner' && (
                                                        <Button 
                                                            variant="danger" 
                                                            size="sm" 
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent triggering parent onClick
                                                                handleDeleteTable(table.id);
                                                            }}
                                                            data-tour="delete-table-button"
                                                        >
                                                            <i className="bi bi-trash me-1"></i> {t('delete')}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>


                                            {/* Display table capacity */}
                                            <p className="mt-3">
                                                <strong>{t('capacity')}:</strong> {table.capacity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="col-12">
                            <div className="alert alert-info text-center" role="alert">
                                {t('noTablesAvailable')}
                            </div>
                        </div>
                    )}
                </div>

                {/* Optional: Additional Features like Recent Orders */}
                <div className="mt-5">
                    <h2 data-tour="recent-orders-table"><i className="bi bi-clock-history fs-4 me-2"></i> {t('recentOrders')}</h2>
                    {orders.length > 0 ? (
                        <div className="table-responsive">
                            <table className="table table-striped table-hover mt-3">
                            <thead className="table-dark">
                                <tr>
                                    {/* Removed the # (Data) and Customer Name columns */}
                                    <th scope="col">{t('tableNumber')}</th>
                                    <th scope="col">{t('status')}</th>
                                    {/* Removed the Time Column */}
                                </tr>
                            </thead>
                            <tbody>
                                {orders.slice(0, 5).map((order, idx) => (
                                    <tr 
                                        key={order.id} 
                                        onClick={() => navigate(`/orders/${restaurantId}/${order.id}`)} 
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Removed the # (Data) and Customer Name cells */}
                                        <td>{getTableNumberById(order.table)}</td>
                                        <td>
                                            <span className={`badge bg-${getBadgeColor(order.status)}`}>
                                                {t(`statuses.${order.status.toLowerCase()}`)}
                                            </span>
                                        </td>
                                        {/* Removed the Time Cell */}
                                    </tr>
                                ))}
                            </tbody>

                            </table>
                        </div>
                    ) : (
                        <div className="alert alert-warning text-center" role="alert">
                            {t('noOrdersCurrently')}
                        </div>
                    )}
                </div>
            </div>

            {/* Help Request Details Modal */}
            <Modal show={showHelpDetailsModal} onHide={handleCloseHelpDetails} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('helpRequest.details')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedHelpRequest && (
                        <>
                            <p><strong>{t('tableNumber')}:</strong> {getTableNumberById(selectedHelpRequest.table)}</p>
                            <p><strong>{t('username')}:</strong> {selectedHelpRequest.user || t('anonymous')}</p>
                            <p><strong>{t('description')}:</strong> {selectedHelpRequest.description || t('noDescription')}</p>
                            <p><strong>{t('status')}:</strong> {t(`statuses.${selectedHelpRequest.status.toLowerCase()}`)}</p>
                            {selectedHelpRequest.response && (
                                <p><strong>{t('helpRequest.response')}:</strong> {selectedHelpRequest.response}</p>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHelpDetails}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Response Modal */}
            <Modal show={showResponseModal} onHide={() => setShowResponseModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {currentAction === 'accept' ? t('helpRequest.respondToAccept') : t('helpRequest.respondToDecline')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="responseText">
                        <Form.Label>{t('helpRequest.yourResponse')}</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            value={responseText}
                            onChange={(e) => setResponseText(e.target.value)}
                            placeholder={t('helpRequest.enterResponse')}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowResponseModal(false)}>
                        {t('cancel')}
                    </Button>
                    <Button 
                        variant={currentAction === 'accept' ? 'success' : 'danger'} 
                        onClick={handleSubmitResponse}
                    >
                        {currentAction === 'accept' ? t('accept') : t('decline')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Table Modal */}
            <Modal show={showAddTableModal} onHide={() => setShowAddTableModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('addTable.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitAddTable}>
                        <Form.Group controlId="tableNumber">
                            <Form.Label>{t('addTable.tableNumber')}</Form.Label>
                            <Form.Control 
                                type="number" 
                                min="1" 
                                value={newTableNumber}
                                onChange={(e) => setNewTableNumber(e.target.value)}
                                placeholder={t('addTable.enterTableNumber')}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="tableCapacity" className="mt-3">
                            <Form.Label>{t('addTable.capacity')}</Form.Label>
                            <Form.Control 
                                type="number" 
                                min="1" 
                                value={newTableCapacity}
                                onChange={(e) => setNewTableCapacity(e.target.value)}
                                placeholder={t('addTable.enterCapacity')}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            {t('addTable.submit')}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );

};

export default DashboardPage;
