// src/pages/MenuSelectionPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMenus } from '../services/api';
import { Modal, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MenuSelectionPage = () => {
    const { restaurantId, tableId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [menus, setMenus] = useState([]);
    const [showMenuModal, setShowMenuModal] = useState(false);

    // Mapping of language codes to display names
    const languageNames = {
        'en': 'English',
        'ar': 'العربية',
        'tr': 'Türkçe',
        'de': 'Deutsch',
        'nl': 'Nederlands',
        // Add more language codes and display names as needed
    };

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const response = await getMenus(restaurantId);
                console.log(response);
                const menusData = response.data;
                setMenus(menusData);

                if (menusData.length === 0) {
                    toast.error(t('No menus available for this restaurant.'));
                } else if (menusData.length === 1) {
                    // If only one menu, navigate directly to it
                    navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${menusData[0].id}`);
                } else {
                    // If multiple menus, show the modal for selection
                    setShowMenuModal(true);
                }
            } catch (error) {
                console.error('Error fetching menus:', error);
                toast.error(t('Error fetching menus.'));
            }
        };

        fetchMenus();
    }, [restaurantId, tableId, navigate, t]);

    const handleLanguageSelect = (language) => {
        setShowMenuModal(false);
        // Find the menu with the selected language
        const selectedMenu = menus.find((menu) => menu.language === language);
        if (selectedMenu) {
            navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${selectedMenu.id}`);
        } else {
            toast.error(t('No menu available for the selected language.'));
        }
    };

    // Extract unique languages
    const uniqueLanguages = [];
    const languageSet = new Set();
    menus.forEach((menu) => {
        if (!languageSet.has(menu.language)) {
            languageSet.add(menu.language);
            const language_display = languageNames[menu.language] || menu.language;
            uniqueLanguages.push({ language: menu.language, language_display });
        }
    });

    return (
        <>
            {/* Menu Selection Modal */}
            <Modal show={showMenuModal} onHide={() => {}} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>{t('Please select a language')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {uniqueLanguages.map(({ language, language_display }) => (
                            <ListGroup.Item
                                key={language}
                                action
                                onClick={() => handleLanguageSelect(language)}
                            >
                                {language_display}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MenuSelectionPage;
