// src/pages/CustomerMenuPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getRestaurantPublicDetails, getMenus, getCategories, getTableNumber } from '../services/api';
import Footer from '../components/Footer';
import '../styles/Footer.css';
import '../styles/CustomerMenuPage.css';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Spinner, Alert, Modal, ListGroup } from 'react-bootstrap';

const CustomerMenuPage = ({ basketItems, addToBasket }) => {
    const { t, i18n } = useTranslation();
    const { restaurantId, tableId, menuId } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [menus, setMenus] = useState([]);
    const [availableMenus, setAvailableMenus] = useState([]);
    const [showMenuSelectionModal, setShowMenuSelectionModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [restaurantBackground, setRestaurantBackground] = useState(null);
    const [restaurantName, setRestaurantName] = useState('');
    const [tableNumber, setTableNumber] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch restaurant details
                const restaurantResponse = await getRestaurantPublicDetails(restaurantId);
                setRestaurantBackground(restaurantResponse.data.background_image_url);
                setRestaurantName(restaurantResponse.data.name);

                // Fetch menus
                const menusResponse = await getMenus(restaurantId);
                const menusData = menusResponse.data;
                setMenus(menusData);

                let selectedMenuId = menuId;

                if (!selectedMenuId) {
                    // No menuId in URL
                    if (menusData.length === 1) {
                        // Only one menu, use it
                        selectedMenuId = menusData[0].id;
                        // Navigate to URL with menuId
                        navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${selectedMenuId}`, { replace: true });
                    } else {
                        // Multiple menus
                        // Try to find menu matching current language
                        const menuForLanguage = menusData.find(menu => menu.language === i18n.language);

                        if (menuForLanguage) {
                            selectedMenuId = menuForLanguage.id;
                            // Navigate to URL with menuId
                            navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${selectedMenuId}`, { replace: true });
                        } else {
                            // No menu for current language
                            // Show pop-up to choose menu
                            setAvailableMenus(menusData);
                            setShowMenuSelectionModal(true);
                            setLoading(false);
                            return; // Wait for user to select menu
                        }
                    }
                } else {
                    // MenuId is provided, check if it exists and matches current language
                    const selectedMenu = menusData.find(menu => menu.id.toString() === selectedMenuId);
                    if (!selectedMenu) {
                        // Invalid menuId, show error
                        setError(t('customerMenuPage.errors.invalidMenu'));
                        setLoading(false);
                        return;
                    } else if (selectedMenu.language !== i18n.language) {
                        // Language changed, try to find menu for new language
                        const menuForLanguage = menusData.find(menu => menu.language === i18n.language);
                        if (menuForLanguage) {
                            selectedMenuId = menuForLanguage.id;
                            // Navigate to URL with menuId
                            navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${selectedMenuId}`, { replace: true });
                            return; // useEffect will re-run
                        } else {
                            // No menu for new language, keep current menu
                        }
                    }
                }

                // Fetch categories for selected menu
                const categoriesResponse = await getCategories(restaurantId, selectedMenuId);
                setCategories(categoriesResponse.data);

                // Fetch table number
                const tableResponse = await getTableNumber(restaurantId, tableId);
                setTableNumber(tableResponse.data.number);

                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(t('customerMenuPage.errors.fetchFailed'));
                setLoading(false);
            }
        };

        fetchData();
    }, [restaurantId, tableId, menuId, i18n.language, navigate, t]);

    // Handle language change
    useEffect(() => {
        if (menus.length > 0 && menuId) {
            const menuForLanguage = menus.find(menu => menu.language === i18n.language);
            if (menuForLanguage && menuForLanguage.id.toString() !== menuId) {
                // Navigate to the menu for the new language
                navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${menuForLanguage.id}`);
            }
        }
    }, [i18n.language, menus, menuId, navigate, restaurantId, tableId]);

    // Handle category click and include tableId and menuId in the route
    const handleCategoryClick = (categoryId) => {
        navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${menuId}/category/${categoryId}`);
    };

    const handleMenuSelect = (selectedMenuId) => {
        setShowMenuSelectionModal(false);
        navigate(`/restaurant/${restaurantId}/table/${tableId}/menu/${selectedMenuId}`);
    };

    if (loading) {
        return (
            <Container className="my-5 text-center">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">{t('customerMenuPage.loading')}</span>
                </Spinner>
                <p className="mt-3">{t('customerMenuPage.loading')}</p>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="my-5">
                <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                    {error}
                </Alert>
            </Container>
        );
    }

    return (
        <div className="container customer-menu-page" dir={i18n.dir()} style={{ position: 'relative', paddingBottom: '100px' }}>
            {/* Background Image */}
            {restaurantBackground && (
                <div
                    className="background-image"
                    style={{
                        backgroundImage: `url(${restaurantBackground})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1,
                    }}
                    aria-label={t('customerMenuPage.aria.backgroundImage', { restaurantName })}
                />
            )}

            {/* Content */}
            <div className="content">
                <h1 className="text-center my-4">
                    {t('customerMenuPage.title', { tableNumber })}
                </h1>
                <Row className="g-4">
                    {categories.length > 0 ? (
                        categories.map(category => (
                            <Col key={category.id} xs={6} md={4} lg={3}>
                                <Card
                                    className="h-100 border-0 shadow-sm category-card"
                                    onClick={() => handleCategoryClick(category.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {category.image_url && (
                                        <Card.Img
                                            src={category.image_url}
                                            alt={category.name}
                                            className="card-img-top img-fluid rounded-top"
                                            style={{ height: '150px', objectFit: 'cover' }}
                                        />
                                    )}
                                    <Card.Body className="d-flex flex-column justify-content-between">
                                        <Card.Title className="text-center">{category.name}</Card.Title>
                                        <Card.Text className="text-center">
                                            {category.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            <p className="text-center">{t('customerMenuPage.noCategories')}</p>
                        </Col>
                    )}
                </Row>
            </div>

            {/* Menu Selection Modal */}
            <Modal show={showMenuSelectionModal} onHide={() => setShowMenuSelectionModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('customerMenuPage.selectMenu')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {availableMenus.map(menu => (
                            <ListGroup.Item key={menu.id} action onClick={() => handleMenuSelect(menu.id)}>
                                {menu.name} ({menu.language})
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CustomerMenuPage;
