// src/pages/OrderBasketPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { placeOrder, getRestaurantPublicDetails } from '../services/api';
import Footer from '../components/Footer';
import PropTypes from 'prop-types';
import '../styles/Footer.css';
import '../styles/OrderBasketPage.css';
import '../App.css';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  Image,
  Spinner,
  Alert,
  Badge,
  InputGroup,
  Form,
} from 'react-bootstrap';
import {
  FaShoppingCart,
  FaCheckCircle,
  FaTimesCircle,
  FaDollarSign,
  FaPlusCircle,
  FaMinusCircle,
  FaArrowLeft, // Existing back button
  FaInfoCircle,
  FaReceipt,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const OrderBasketPage = ({
  basketItems,
  updateBasketItem,
  removeBasketItem,
  setBasketItems,
}) => {
  const { t, i18n } = useTranslation();
  const { restaurantId, tableId } = useParams();
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [restaurantBackground, setRestaurantBackground] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch restaurant details and set background
  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      try {
        const restaurantResponse = await getRestaurantPublicDetails(restaurantId);
        console.log('Restaurant ID:', restaurantId);
        const restaurantData = restaurantResponse.data;
        console.log('Fetched Restaurant Data:', restaurantData);
        setRestaurantBackground(restaurantData.background_image_url);
      } catch (error) {
        console.error(t('orderBasketPage.errors.fetchRestaurantDetails'), error);
        setError(t('orderBasketPage.errors.fetchRestaurantDetails'));
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      fetchRestaurantDetails();
    }
  }, [restaurantId, t]);

  // Group items by checking if options are the same
  const groupedBasketItems = basketItems.reduce((acc, item) => {
    const existingItem = acc.find((groupedItem) => {
      return (
        groupedItem.id === item.id &&
        JSON.stringify(groupedItem.selectedOptions) ===
          JSON.stringify(item.selectedOptions)
      );
    });

    if (existingItem) {
      existingItem.quantity += item.quantity;
      console.log(
        `Grouped Item Found: ${existingItem.name} | New Quantity: ${existingItem.quantity}`
      );
    } else {
      acc.push({ ...item });
      console.log(`Added New Grouped Item: ${item.name} | Quantity: ${item.quantity}`);
    }

    return acc;
  }, []);

  console.log('Grouped Basket Items:', groupedBasketItems);

  // Calculate total price whenever the grouped basket changes
  useEffect(() => {
    const calculateTotalPrice = () => {
      let total = 0;
      console.log('Starting Total Price Calculation');
      groupedBasketItems.forEach((item, index) => {
        console.log(`\nProcessing Item ${index + 1}: ${item.name}`);
        let itemTotal = parseFloat(item.price);
        console.log(`  Base Price: ${itemTotal}`);

        // Add price based on selected options
        if (item.selectedOptions) {
          Object.values(item.selectedOptions).forEach((option) => {
            if (option.price_modifier) {
              const modifier = parseFloat(option.price_modifier);
              console.log(`  Option: ${option.name} | Price Modifier: ${modifier}`);
              itemTotal += modifier;
            } else {
              console.log(`  Option: ${option.name} | No Price Modifier`);
            }
          });
        }

        console.log(`  Price after Options: ${itemTotal}`);

        // Multiply by the quantity
        itemTotal *= item.quantity;
        console.log(`  Quantity: ${item.quantity} | Item Total: ${itemTotal}`);

        total += itemTotal;
      });
      console.log(`Total Price Calculated: ${total}`);
      setTotalPrice(total);
    };

    calculateTotalPrice();
  }, [groupedBasketItems]);

  // Handle quantity changes
  const handleQuantityChange = (item, quantity) => {
    console.log(`\nChanging Quantity for Item: ${item.name} | New Quantity: ${quantity}`);
    if (quantity > 0) {
      updateBasketItem(item, quantity);
    }
  };

  // Handle option changes
  const handleOptionChange = (item, optionName, choice) => {
    console.log(
      `\nChanging Option for Item: ${item.name} | Option: ${optionName} | Choice: ${choice.name}`
    );
    const updatedItem = {
      ...item,
      selectedOptions: {
        ...item.selectedOptions,
        [optionName]: choice,
      },
    };
    updateBasketItem(updatedItem, item.quantity);
  };

  const handlePlaceOrder = async () => {
    try {
      if (!restaurantId || !tableId) {
        alert(t('orderBasketPage.errors.invalidRestaurantOrTable'));
        console.error('Invalid Restaurant ID or Table ID');
        return;
      }

      // Construct the order payload
      const orderPayload = {
        restaurant: restaurantId,
        table: tableId, // Update this to match backend's `table` UUID field
        items: basketItems.map((item) => ({
          menu_item: item.id,
          quantity: item.quantity,
          selected_options: item.selectedOptions
            ? Object.values(item.selectedOptions).map((option) => option.id) // Extract IDs
            : [],
          special_request: item.special_request || '',
        })),
      };

      console.log('Placing Order with Payload:', JSON.stringify(orderPayload, null, 2));

      const response = await placeOrder(orderPayload);

      if (response.status === 201) {
        alert(t('orderBasketPage.success.orderPlaced'));
        console.log('Order placed successfully.');
        setBasketItems([]);
        localStorage.removeItem('basket');
        navigate(`/restaurant/${restaurantId}/order-success/${tableId}`);
      } else {
        alert(t('orderBasketPage.errors.orderFailed'));
        console.error('Order placement failed with status:', response.status);
      }
    } catch (error) {
      console.error(t('orderBasketPage.errors.placeOrderError'), error);
      alert(t('orderBasketPage.errors.placeOrderError'));
    }
  };

  if (loading) {
    return (
      <Container className="my-5 text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">{t('orderBasketPage.loading')}</span>
        </Spinner>
        <p className="mt-3">{t('orderBasketPage.loading')}</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="my-5">
        <Alert variant="danger" className="d-flex align-items-center">
          <FaTimesCircle className="me-2" />
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <div
      className="order-basket-page"
      dir={i18n.dir()}
      style={{ position: 'relative', paddingBottom: '100px' }}
    >
      {/* Background image for restaurant */}
      {restaurantBackground && (
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${restaurantBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            filter: 'blur(4px)',
            zIndex: -1,
          }}
          aria-label={t('orderBasketPage.aria.backgroundImage')}
        />
      )}

      {/* Content Container */}
      <Container className="my-5">
        {/* Conditionally render the first "Go Back" button only when the basket is empty */}
        {groupedBasketItems.length === 0 && (
          <div className="mb-4 text-center">
            <Button
              variant="secondary"
              onClick={() => navigate(-1)}
              className="d-flex align-items-center"
              aria-label={t('orderBasketPage.goBackButtonAriaLabel')}
            >
              <FaArrowLeft className="me-2" />
              {t('orderBasketPage.goBack')}
            </Button>
          </div>
        )}

        <Row className="justify-content-center">
          <Col lg={10} md={12}>
            <Card className="bg-light shadow-lg p-4 rounded">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="d-flex align-items-center">
                  <FaShoppingCart className="me-2 text-primary" />
                  {t('orderBasketPage.title')}
                </h2>
              </div>

              {groupedBasketItems.length === 0 ? (
                <>
                  <Alert variant="info" className="text-center">
                    <FaInfoCircle className="me-2" />
                    {t('orderBasketPage.emptyBasket')}
                  </Alert>
                </>
              ) : (
                <>
                  <ListGroup variant="flush" className="mb-4">
                    {groupedBasketItems.map((item, index) => (
                      <ListGroup.Item key={index} className="mb-3 px-2 py-2">
                        <Row className="align-items-center">
                          {/* Item Image */}
                          <Col md={2} sm={3} xs={4} className="text-center mb-3 mb-md-0">
                            <Image
                              src={item.image}
                              alt={item.name}
                              rounded
                              fluid
                              style={{ maxHeight: '80px', objectFit: 'cover' }}
                              loading="lazy"
                            />
                            {/* Quantity Controls Below Image on Small Screens */}
                            <div className="d-md-none mt-2">
                              <InputGroup size="sm" className="d-flex align-items-center justify-content-center">
                                <Button
                                  variant="outline-primary"
                                  onClick={() => handleQuantityChange(item, item.quantity - 1)}
                                  disabled={item.quantity <= 1}
                                  aria-label={t('orderBasketPage.decreaseQuantity')}
                                  className="btn-sm p-0"
                                >
                                  <FaMinusCircle />
                                </Button>
                                <Form.Control
                                  type="text"
                                  readOnly
                                  value={item.quantity}
                                  className="text-center mx-1"
                                  style={{ width: '25px', fontSize: '0.7rem' }}
                                  aria-label={t('orderBasketPage.quantity')}
                                />
                                <Button
                                  variant="outline-primary"
                                  onClick={() => handleQuantityChange(item, item.quantity + 1)}
                                  aria-label={t('orderBasketPage.increaseQuantity')}
                                  className="btn-sm p-0"
                                >
                                  <FaPlusCircle />
                                </Button>
                              </InputGroup>
                            </div>
                          </Col>

                          {/* Item Details */}
                          <Col md={7} sm={6} xs={8}>
                            <div className="d-flex justify-content-between align-items-start">
                              <div>
                                <h5 className="mb-1">
                                  <FaCheckCircle className="me-2 text-success" />
                                  {item.name}
                                </h5>
                                <p className="mb-1">
                                  <FaDollarSign className="me-2 text-warning" />
                                  {t('orderBasketPage.basePrice')}: ${parseFloat(item.price).toFixed(2)}
                                </p>
                                <p className="mb-1">
                                  <FaReceipt className="me-2 text-info" />
                                  {t('orderBasketPage.quantity')}: {item.quantity}
                                </p>
                                {/* Selected Options */}
                                {item.selectedOptions && (
                                  <div className="mb-1">
                                    <strong>{t('orderBasketPage.selectedOptions')}:</strong>
                                    {Object.keys(item.selectedOptions).map((optionName) => (
                                      <div key={optionName}>
                                        {optionName}: {item.selectedOptions[optionName].name}
                                        {item.selectedOptions[optionName].price_modifier
                                          ? ` (+${item.selectedOptions[optionName].price_modifier} ${t(
                                              'orderBasketPage.currency'
                                            )})`
                                          : ''}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              {/* Quantity Controls for Medium and Larger Screens */}
                              <div className="d-none d-md-flex align-items-center">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => handleQuantityChange(item, item.quantity - 1)}
                                    disabled={item.quantity <= 1}
                                    aria-label={t('orderBasketPage.decreaseQuantity')}
                                    className="btn-sm p-1"
                                  >
                                    <FaMinusCircle />
                                  </Button>
                                  <Form.Control
                                    type="text"
                                    readOnly
                                    value={item.quantity}
                                    className="text-center mx-1"
                                    style={{ width: '30px', fontSize: '0.8rem' }}
                                    aria-label={t('orderBasketPage.quantity')}
                                  />
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => handleQuantityChange(item, item.quantity + 1)}
                                    aria-label={t('orderBasketPage.increaseQuantity')}
                                    className="btn-sm p-1"
                                  >
                                    <FaPlusCircle />
                                  </Button>
                                </InputGroup>
                              </div>
                            </div>
                          </Col>

                          {/* Item Actions */}
                          <Col md={3} sm={3} xs={12} className="text-md-end text-center">
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => removeBasketItem(item)}
                              aria-label={t('orderBasketPage.removeItem')}
                              className="mt-2 mt-md-0"
                            >
                              <FaTimesCircle className="me-1" /> {t('orderBasketPage.remove')}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  {/* Total Price */}
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0">
                      <FaDollarSign className="me-2 text-success" />
                      {t('orderBasketPage.totalPrice')}:
                    </h4>
                    <h3 className="mb-0">${totalPrice.toFixed(2)}</h3>
                  </div>

                  {/* Action Buttons */}
                  <div className="d-flex justify-content-between flex-column flex-md-row">
                    {/* Existing "Go Back" Button for Non-Empty Basket */}
                    <Button
                      variant="secondary"
                      onClick={() => navigate(-1)}
                      className="d-flex align-items-center mb-3 mb-md-0"
                      aria-label={t('orderBasketPage.goBackButtonAriaLabel')}
                    >
                      <FaArrowLeft className="me-2" />
                      {t('orderBasketPage.goBack')}
                    </Button>
                    <Button
                      variant="success"
                      onClick={handlePlaceOrder}
                      className="d-flex align-items-center"
                    >
                      <FaReceipt className="me-2" />
                      {t('orderBasketPage.placeOrder')}
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

OrderBasketPage.propTypes = {
  basketItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      selectedOptions: PropTypes.object,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          choices: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              price_modifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
          ),
        })
      ),
      special_request: PropTypes.string,
    })
  ).isRequired,
  updateBasketItem: PropTypes.func.isRequired,
  removeBasketItem: PropTypes.func.isRequired,
  setBasketItems: PropTypes.func.isRequired,
};

export default OrderBasketPage;
