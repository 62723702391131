// src/pages/MenuManagementPage.js

import React, { useEffect, useState, useContext } from 'react';
import { Container, Button, Table, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { getMenus, createMenu, deleteMenu }  from '../services/api';  // Import the functions
import { useTranslation } from 'react-i18next';

const MenuManagementPage = () => {
  const { userRoles } = useContext(AuthContext);
  const { restaurant_id } = userRoles;
  const { t } = useTranslation();

  const [menus, setMenus] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newMenuName, setNewMenuName] = useState('');
  const [newMenuLanguage, setNewMenuLanguage] = useState('en');
  const [isDefault, setIsDefault] = useState(false);
  const [error, setError] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState(null);

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    try {
      const response = await getMenus(restaurant_id);
      setMenus(response.data);
    } catch (err) {
      setError(t('errorFetchingMenus'));
      console.error(err);
    }
  };

  const handleCreateMenu = async () => {
    try {
      const payload = {
        name: newMenuName,
        language: newMenuLanguage,
        is_default: isDefault,
      };
      await createMenu(restaurant_id, payload);
      setShowCreateModal(false);
      setNewMenuName('');
      setNewMenuLanguage('en');
      setIsDefault(false);
      fetchMenus();
    } catch (err) {
      setError(t('errorCreatingMenu'));
      console.error(err);
    }
  };

  const handleDeleteMenu = async () => {
    if (!menuToDelete) return;

    try {
      await deleteMenu(restaurant_id, menuToDelete.id);
      setShowDeleteModal(false);
      setMenuToDelete(null);
      fetchMenus();
    } catch (err) {
      setError(t('errorDeletingMenu'));
      console.error(err);
    }
  };

  return (
    <Container>
      <h1 className="my-4">{t('menusManagement')}</h1>
      {error && <p className="text-danger">{error}</p>}

      <Button variant="primary" onClick={() => setShowCreateModal(true)} className="mb-3">
        {t('createNewMenu')}
      </Button>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('menuName')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {menus.map((menu) => (
            <tr key={menu.id}>
              <td>{menu.name}</td>
              <td>
                <Row className="g-2">
                  <Col xs="auto">
                    <Button
                      as={Link}
                      to={`/restaurant/${restaurant_id}/menus-management/${menu.id}/manage-categories`}
                      variant="info"
                      className="me-2"
                      data-tour="manage-categories-button"
                    >
                      {t('manageCategories')}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      as={Link}
                      to={`/restaurant/${restaurant_id}/menus-management/${menu.id}/manage-menu-items`}
                      variant="secondary"
                      className="me-2"
                      data-tour="manage-menu-items-button"
                    >
                      {t('manageMenuItems')}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="danger"
                      onClick={() => {
                        setMenuToDelete(menu);
                        setShowDeleteModal(true);
                      }}
                      data-tour="delete-menu-button"
                    >
                      {t('delete')}
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
          {menus.length === 0 && (
            <tr>
              <td colSpan="2" className="text-center">
                {t('noMenusAvailable')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Create Menu Modal */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewMenu')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formMenuName" className="mb-3">
              <Form.Label>{t('menuName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('enterMenuName')}
                value={newMenuName}
                onChange={(e) => setNewMenuName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formMenuLanguage" className="mb-3">
              <Form.Label>{t('language')}</Form.Label>
              <Form.Control
                as="select"
                value={newMenuLanguage}
                onChange={(e) => setNewMenuLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="ar">العربية</option>
                <option value="tr">Türkçe</option>
                <option value="de">Deutsch</option>
                <option value="nl">Nederlands</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formIsDefault" className="mb-3">
              <Form.Check
                type="checkbox"
                label={t('setAsDefault')}
                checked={isDefault}
                onChange={(e) => setIsDefault(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateMenu}>
            {t('create')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirmDelete')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('strongDeleteConfirmation')}</p>
          {menuToDelete && (
            <p>
              <strong>{menuToDelete.name}</strong>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={handleDeleteMenu}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MenuManagementPage;
