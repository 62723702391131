// src/services/api.js

import axios from 'axios';

const API_URL = 'https://restaurant-order-system-a38af740ae3a.herokuapp.com/api/';

// Helper to get token from localStorage
const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return token ? { Authorization: `Bearer ${token}` } : {};
};

// -------------------------------- Existing API Functions ---------------------------------

// Get Orders (Requires Authentication)
export const getOrders = (restaurantId) => {
    return axios.get(`${API_URL}orders/?restaurant=${restaurantId}`, {
        headers: getAuthHeaders(),
    });
};

// Delete Order (Requires Authentication)
export const deleteOrder = (orderId) => {
    return axios.delete(`${API_URL}orders/${orderId}/`, {
        headers: getAuthHeaders(),
    });
};

// Place an Order (Public Access)
export const placeOrder = async (data) => {
    try {
        console.log(data);
        const response = await axios.post(`${API_URL}orders/`, data);
        return response;  // Return the full response
    } catch (error) {
        throw error.response;  // Throw the error response to be caught in the frontend
    }
};

// Update order status (Requires Authentication)
export const updateOrderStatus = (orderId, statusData) => {
    return axios.patch(`${API_URL}orders/${orderId}/`, statusData, {
        headers: getAuthHeaders(),
    });
};

// Fetch QR codes for tables and total table count (Requires Authentication)
export const getQrCodes = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/get_qr_codes/`, {
        headers: getAuthHeaders(),
    });
};

// Add a new table (Requires Authentication)
export const addTable = (restaurantId, tableData) => {
    return axios.post(`${API_URL}restaurants/${restaurantId}/tables/`, tableData, {
        headers: getAuthHeaders(),
    });
};

// Remove a table by table ID (Requires Authentication)
export const removeTable = (restaurantId, tableId) => {
    return axios.delete(`${API_URL}restaurants/${restaurantId}/tables/${tableId}/`, {
        headers: getAuthHeaders(),
    });
};

// Fetch tables for a restaurant (Requires Authentication)
export const getTables = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/tables/`, {
        headers: getAuthHeaders(),
    });
};

// Download QR code for a table (Requires Authentication)
export const downloadQrCode = (restaurantId, tableId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/tables/${tableId}/download_qr_code/`, {
        headers: getAuthHeaders(),
        responseType: 'blob', // Important for handling binary data
    });
};

// Get Order Status by Restaurant and Table Number (Public Access)
export const getOrderStatus = async (restaurantId, tableNumber) => {
    try {
        console.log("mew");
        console.log(restaurantId);
        console.log(tableNumber);
        const response = await axios.get(`${API_URL}orders/status`, {
            params: { restaurant: restaurantId, table_number: tableNumber },
        });
        return response.data || [];
    } catch (error) {
        console.error("Error fetching order status:", error);
        return [];
    }
};

// Get Restaurant Public Details (Public Access)
export const getRestaurantPublicDetails = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/public-details/`);
};

// Get Menus for a Restaurant (Public Access)
export const getMenus = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/`);
};

// Get Categories within a Menu (Public Access)
export const getCategories = (restaurantId, menuId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/categories/`);
};

// Get Menu Items by Category (Public Access)
export const getMenuItemsByCategory = (restaurantId, menuId, categoryId) => {
    console.log(categoryId);
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items-by-category/${categoryId}/`);
};

// Get Menu Item Details (Public Access)
export const getMenuItemDetails = (restaurantId, menuId, itemId) => {
    return axios.get(
      `${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/${itemId}/`
    );
  };
  

// Get Table Number by Table ID (Public Access)
export const getTableNumber = (restaurantId, tableId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/tables/${tableId}/number/`);
};

// Combined registration function (Public Access)
export const registerUserAndRestaurant = (registrationData) => {
    return axios.post(`${API_URL}register/`, registrationData, {
        headers: { 'Content-Type': 'application/json' },
    });
};

// Function to create a new user (Public Access)
export const createUser = async (userData) => {
    const response = await axios.post(`${API_URL}register-user/`, { user: userData }, {
        headers: { 'Content-Type': 'application/json' }
    });
    return response;
};

// Function to add staff to a restaurant (Requires Authentication)
export const addStaff = async (restaurantId, staffUsernames) => {
    const response = await axios.post(`${API_URL}restaurants/${restaurantId}/add-staff/`, {
        staff: staffUsernames
    }, {
        headers: getAuthHeaders()
    });
    return response;
};

// Function to remove staff from a restaurant (Requires Authentication)
export const removeStaff = async (restaurantId, staffUsernames) => {
    const response = await axios.delete(`${API_URL}restaurants/${restaurantId}/remove-staff/`, {
        headers: getAuthHeaders(),
        data: { staff: staffUsernames }
    });
    return response;
};

// Validate User Data (Public Access)
export const validateUserData = (userData) => {
    return axios.post(`${API_URL}validate-user/`, userData);
};

// Create a Help Request (Public Access)
export const createHelpRequest = (helpRequestData) => {
    return axios.post(`${API_URL}help-requests/`, helpRequestData);
};

// Get a Single Help Request (Requires Authentication)
export const getHelpRequestById = (helpRequestId) => {
    return axios.get(`${API_URL}help-requests/${helpRequestId}/`, {
        headers: getAuthHeaders(),
    });
};

// Fetch Help Requests for a specific restaurant (Requires Authentication)
export const fetchHelpRequests = (restaurantId) => {
    return axios.get(`${API_URL}help-requests/`, {
        headers: getAuthHeaders(),
        params: { restaurant: restaurantId }
    });
};

// Accept a Help Request (Requires Authentication)
export const acceptHelpRequest = (helpRequestId, responseText) => {
    return axios.patch(`${API_URL}help-requests/${helpRequestId}/`, {
        status: 'Accepted',
        response: responseText
    }, {
        headers: getAuthHeaders(),
    });
};

// Decline a Help Request (Requires Authentication)
export const declineHelpRequest = (helpRequestId, responseText) => {
    return axios.patch(`${API_URL}help-requests/${helpRequestId}/`, {
        status: 'Declined',
        response: responseText
    }, {
        headers: getAuthHeaders(),
    });
};

// Delete a Help Request (Requires Authentication)
export const deleteHelpRequest = (helpRequestId) => {
    return axios.delete(`${API_URL}help-requests/${helpRequestId}/`, {
        headers: getAuthHeaders(),
    });
};

// Resolve a Help Request (Requires Authentication)
export const resolveHelpRequest = (helpRequestId, responseText) => {
    return axios.patch(`${API_URL}help-requests/${helpRequestId}/`, {
        status: 'Resolved',
        response: responseText
    }, {
        headers: getAuthHeaders(),
    });
};

// Activate account using uidb64 and token (Public Access)
export const activateAccount = (uidb64, token) => {
    return axios.get(`${API_URL}activate/${uidb64}/${token}/`);
};

// Request password reset link via email (Public Access)
export const requestPasswordReset = (email) => {
    return axios.post(`${API_URL}password-reset/`, { email });
};

// Confirm password reset with uidb64 and token (Public Access)
export const confirmPasswordReset = (uidb64, token, newPassword, confirmPassword) => {
    return axios.post(`${API_URL}password-reset-confirm/${uidb64}/${token}/`, {
        new_password: newPassword,
        confirm_password: confirmPassword,
    });
};

// Recover username via email (Public Access)
export const recoverUsername = (email) => {
    return axios.post(`${API_URL}username-recovery/`, { email });
};

// ------------------------------ New API Functions for Menu Management ------------------------------

// Create a new Menu (Requires Authentication)
export const createMenu = (restaurantId, menuData) => {
    return axios.post(`${API_URL}restaurants/${restaurantId}/menus/`, menuData, {
        headers: getAuthHeaders(),
    });
};

// Delete a Menu (Requires Authentication)
export const deleteMenu = (restaurantId, menuId) => {
    return axios.delete(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/`, {
        headers: getAuthHeaders(),
    });
};

// Optionally, Get a Single Menu by ID (Public Access)
export const getMenuById = (restaurantId, menuId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/`);
};

// Optionally, Update a Menu (Requires Authentication)
export const updateMenu = (restaurantId, menuId, menuData) => {
    return axios.patch(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/`, menuData, {
        headers: getAuthHeaders(),
    });
};

// ------------------------------ Updated Categories API Functions ------------------------------

// Create a new Category within a specific Menu (Requires Authentication)
export const createCategory = (restaurantId, menuId, formData) => {
    return axios.post(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/categories/`, formData, {
        headers: getAuthHeaders(), // Do not set 'Content-Type'; axios sets it automatically
    });
};

// Update an existing Category within a specific Menu (Requires Authentication)
export const updateCategory = (restaurantId, menuId, categoryId, formData) => {
    return axios.patch(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/`, formData, {
        headers: getAuthHeaders(),
    });
};

// Delete a Category within a specific Menu (Requires Authentication)
export const deleteCategory = (restaurantId, menuId, categoryId) => {
    return axios.delete(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/`, {
        headers: getAuthHeaders(),
    });
};

// Get a single Category by ID within a specific Menu (Public Access)
export const getCategoryById = (restaurantId, menuId, categoryId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/`);
};

// ------------------------------ Updated Menu Items API Functions ------------------------------

// Get all Menu Items within a specific Menu (Public Access)
export const getMenuItems = (restaurantId, menuId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/`);
};

// Get a single Menu Item by ID within a specific Menu (Public Access)
export const getMenuItemById = (restaurantId, menuId, menuItemId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/${menuItemId}/`);
};

// Create a new Menu Item within a specific Menu (Requires Authentication)
export const createMenuItem = (restaurantId, menuId, formData) => {
    return axios.post(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/`, formData, {
        headers: getAuthHeaders(),
    });
};

// Update an existing Menu Item within a specific Menu (Requires Authentication)
export const updateMenuItem = (restaurantId, menuId, menuItemId, formData) => {
    return axios.patch(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/${menuItemId}/`, formData, {
        headers: getAuthHeaders(),
    });
};

// Update Menu Item Availability within a specific Menu (Requires Authentication)
export const updateMenuItemAvailability = (restaurantId, menuId, menuItemId, isAvailable) => {
    return axios.patch(
        `${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/${menuItemId}/availability/`,
        { is_available: isAvailable },
        {
            headers: getAuthHeaders(),
        }
    );
};

// Delete a Menu Item within a specific Menu (Requires Authentication)
export const deleteMenuItem = (restaurantId, menuId, menuItemId) => {
    return axios.delete(`${API_URL}restaurants/${restaurantId}/menus/${menuId}/menu-items/${menuItemId}/`, {
        headers: getAuthHeaders(),
    });
};

// ------------------------------ Existing and Other API Functions Continued ------------------------------

// Get Restaurant Profile (Requires Authentication)
export const getRestaurantProfile = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/profile/`, {
        headers: getAuthHeaders(),
    });
};

// Update Restaurant Profile using PATCH (Requires Authentication)
export const updateRestaurantProfile = (restaurantId, data) => {
    return axios.patch(`${API_URL}restaurants/${restaurantId}/update-profile/`, data, {
        headers: { ...getAuthHeaders(), 'Content-Type': 'multipart/form-data' },
    });
};

// Get Staff List for a Restaurant (Requires Authentication)
export const getStaffList = (restaurantId) => {
    return axios.get(`${API_URL}restaurants/${restaurantId}/staff/`, {
        headers: getAuthHeaders(),
    });
};
